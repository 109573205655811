import React, { useEffect } from "react";
import moment from "moment";
// import { Dashboard } from "modules/assortsmart/constants-assortsmart/stringContants";
import { Button } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Delete, Edit } from "@mui/icons-material";
import TextInputFieldSection from "./TextInputFieldSection";

const CommentingMainSection = ({
  commentData,
  handleOpenDropdown,
  setSelectedComment,
  isCommentEditable,
  openCommentDropdown,
  handleCloseDropdown,
  userData,
  selectedComment,
  setCommentEditable,
  setEditCommentTextInput,
  setShowDeleteDialog,
  setDeleteCommentData,
  type,
  classes,
  editCommentFn,
  editCommentTextInput,
  usersMentioned,
  setUserMentioned
}) => {
  useEffect(()=>{
    if(!isCommentEditable){
      document.getElementById(`comment_${commentData.note_code}`).innerHTML = commentData.html_msg;
    }  
  },[commentData,isCommentEditable]);
  
  return (
    <div>
      <div className={classes.headerWrap}>
        <div className={classes.userWrap}>
          <div className={classes.userName}>
            <span>{commentData.created_by_name}</span>
          </div>
          <div className={classes.timeStamp}>
            {moment(commentData.updated_at).format("MM-DD-YYYY")}
          </div>
        </div>
        <Button
          id="action-button"
          onClick={(event) => handleOpenDropdown(event, type)}
        >
          <MoreHorizIcon onClick={() => setSelectedComment(commentData)} />
        </Button>
      </div>
      {!isCommentEditable && (
        <div className={classes.editTextDiv}>
          <span id={`comment_${commentData.note_code}`}>{commentData.html_msg}</span>
        </div>
      )}
      <div>
        {isCommentEditable &&
          commentData.note_code === selectedComment?.note_code && (
            <div className={classes.editTextDiv}>
              <div className={classes.editTextInput}>
                <TextInputFieldSection
                  type="edit"
                  editCommentTextInput={editCommentTextInput}
                  setEditCommentTextInput={setEditCommentTextInput}
                  setCommentEditable={setCommentEditable}
                  selectedComment={selectedComment}
                  editCommentFn={editCommentFn}
                  classes={classes}
                  setUserMentioned={setUserMentioned}
                  usersMentioned={usersMentioned}
                />
              </div>
            </div>
          )}
      </div>
      <Menu
        id="menu"
        anchorEl={openCommentDropdown}
        open={openCommentDropdown}
        onClose={() => handleCloseDropdown(type)}
      >
        {userData.name === selectedComment?.created_by_email ? (
          <>
            <MenuItem
              onClick={() => {
                setCommentEditable(true);
                setEditCommentTextInput(selectedComment.html_msg);
                handleCloseDropdown(type);
              }}
            >
              <Edit /> Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowDeleteDialog(true);
                handleCloseDropdown(type);
                setDeleteCommentData(selectedComment);
              }}
            >
              <Delete /> Delete
            </MenuItem>
          </>
        ) : null}
      </Menu>
    </div>
  );
};
export default CommentingMainSection;
