import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Modal from "@mui/material/Modal";
import { read, utils } from "xlsx";
import { Button, Card, Prompt } from "impact-ui";
import { Typography } from "@mui/material";
import { addSnack } from "actions/snackbarActions";
import makeStyles from "@mui/styles/makeStyles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingOverlay from "Utils/Loader/loader";
import { downloadExcelLink } from "Utils/csv-download";
import { isEmpty, upperFirst } from "lodash";
import { replaceSpecialCharacter } from "Utils/functions/utils";
import globalStyles from "Styles/globalStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    minHeight: "10rem",
  },
  errorText: {
    color: theme.palette.error.main,
  },
  uploadBody: {
    background: theme.palette.common.white,
    display: "block",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  uploader: {
    display: "none",
  },
}));

const UploadHandler = (props) => {
  const {
    isModalOpen = false,
    attachCallBacks,
    templateName = "Template",
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [parsedExcelData, setParsedExcelData] = useState(null);
  const downloadTemplateBtn = useRef(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showFileValidation, setShowFileValidation] = useState(false);
  const [fileValidations, setFileValidations] = useState([]);
  const globalClasses = globalStyles();
  const fileUploadRef = useRef();
  const classes = useStyles();

  useEffect(async () => {
    if (attachCallBacks) {
      attachCallBacks(handleErrorValidationData);
    }

    return () => {
      resetStates();
    };
  }, []);

  useEffect(() => {
    setIsOpen(isModalOpen);
    if (!isModalOpen) {
      resetStates();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isFilePicked) {
      setIsLoading(true);
      props.handleUpload(parsedExcelData, files);
    }
  }, [parsedExcelData]);

  useEffect(() => {
    setShowFileValidation(Boolean(fileValidations?.length));
  }, [fileValidations]);

  /**
   * @function
   * @description Reset local states
   */
  const resetStates = () => {
    setFiles(null);
    setIsFilePicked(false);
    setParsedExcelData(null);
    setFileValidations([]);
    setIsLoading(false);
  };

  /**
   * @function
   * @description Create error HTML Object from error report
   * @returns {HTMLObjectElement}
   */
  const buildErrorReport = () => {
    return fileValidations.map((reports, index) => (
      <div
        key={`error-${reports.length - index}`}
        className={`${globalClasses.flexRow} ${globalClasses.layoutAlignSpaceBetween} ${globalClasses.marginBottom}`}
      >
        {reports.map((data) => (
          <Typography
            className={classes.errorText}
            variant="body1"
            component="span"
            key={data}
          >
            {data}
          </Typography>
        ))}
      </div>
    ));
  };

  /**
   * @function
   * @description Handle error data and create error repport
   * @param {Array} errorData
   */
  const handleErrorValidationData = (errorData) => {
    let errorDescriptions = [];
    errorData?.forEach((error) => {
      props.addSnack({
        message: replaceSpecialCharacter(error.message),
        options: {
          variant: "error",
        },
      });
      if (error.validation_error) {
        const newErrorDescriptions = error.validation_error.map((data) =>
          data.split(",").map((str) => replaceSpecialCharacter(str))
        );
        errorDescriptions = [...errorDescriptions, ...newErrorDescriptions];
      }
    });
    setFileValidations(errorDescriptions);
    setIsLoading(false);
  };

  /**
   * @function
   * @description Read and parse CSV data from uploaded file
   */
  const invokeReader = () => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const targetFile = e.target.result;
      // Use read function from xlxs to read the data from a csv file.
      // The data is read in an array format where commas "," behave as a seperator
      // The second seperator is each row and the final one is every single page
      // When data is read it is in a form of three dimentional matrix.
      let readedData = read(targetFile, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array of arrays */
      const data = utils.sheet_to_json(ws, { blankRows: false, defval: "" });
      setParsedExcelData(data);
    };
    reader.readAsBinaryString(files);
  };

  /**
   * @function
   * @description Update local state objects on input change
   * @param {Object} event
   */
  const changeHandler = (event) => {
    if (event.target.files.length) {
      setFiles(event.target.files[0]);
      setIsFilePicked(true);
    }
    setFileValidations([]);
    setShowFileValidation(false);
  };

  /**
   * @function
   * @description Handle states on Modal close
   */
  const handleModalClose = () => {
    setIsOpen(false);
    if (props.setIsModalOpen) {
      props.setIsModalOpen(false);
    }
    resetStates();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      aria-labelledby="file-upload-modal"
    >
      <>
        <div className={`${classes.uploadBody} ${globalClasses.paddingAround}`}>
          <Card>
            <LoadingOverlay loader={isLoading} spinner>
              <div
                className={`${globalClasses.flexColumn} ${classes.cardWrapper} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginBottom}`}
              >
                <input
                  type="file"
                  id="docpicker"
                  multiple={false}
                  accept={props.acceptFileTypes ? props.acceptFileTypes : ".csv"}
                  onChange={changeHandler}
                  className={classes.uploader}
                  ref={fileUploadRef}
                />
                <Button
                  icon={FileUploadIcon}
                  variant="primary"
                  onClick={() => {
                    fileUploadRef.current?.click();
                  }}
                ></Button>
                {isFilePicked ? (
                  <Typography variant="body1">
                    Filename: {files.name}
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    Select a file.
                    {props?.onlyShowCSVUpload ? " (.csv)" : ` (${props.acceptFileTypes})`}
                  </Typography>
                )}
              </div>
              {showFileValidation && buildErrorReport()}
              <div className={`${globalClasses.flexRow} ${globalClasses.gap}`}>
                {!props?.hideDownloadTemplateButton && (
                  <>
                    <Button
                      variant="primary"
                      id="downloadTemplate"
                      onClick={() => {
                        setShowUploadModal(true);
                      }}
                    >
                      Download Template
                    </Button>
                    {downloadExcelLink(
                      [],
                      templateName,
                      downloadTemplateBtn,
                      [
                        ...props.templateConfig,
                        ...(props.tenantUploadConfig?.extraColumns?.map(
                          (columns) => {
                            return {
                              label: upperFirst(columns.replace("_", " ")),
                              key: columns,
                            };
                          }
                        ) || []),
                      ],
                      "",
                      "",
                      true
                    )}
                  </>
                )}

                {props?.customButtons}

                <div
                  className={`${globalClasses.layoutAlignEnd} ${globalClasses.gap}`}
                >
                  <Button
                    variant="secondary"
                    onClick={() => handleModalClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    disabled={!isFilePicked || isLoading}
                    onClick={() =>
                      props.jsonUpload
                        ? invokeReader()
                        : (setIsLoading(true), props.handleUpload(files))
                    }
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </LoadingOverlay>
          </Card>
        </div>
        <Prompt
          isOpen={showUploadModal}
          title="File upload validations:"
          subHeading={``}
          infoList={[
            ...props.uploadInstructions,
            ...(!isEmpty(props.tenantUploadConfig?.validations)
              ? Object.keys(props.tenantUploadConfig?.validations)
                  .map((key) => props.tenantUploadConfig?.validations[key])
                  .flat()
              : []),
          ]}
          primaryButtonProps={{
            children: "Download",
            onClick: () => {
              downloadTemplateBtn.current.link.click();
              setShowUploadModal(false);
            },
          }}
          tertiaryButtonProps={{
            children: "Cancel",
            onClick: () => setShowUploadModal(false),
          }}
        />
      </>
    </Modal>
  );
};

UploadHandler.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  attachCallBacks: PropTypes.func,
  templateName: PropTypes.string,
  handleUpload: PropTypes.func,
  addSnack: PropTypes.func,
  onlyShowCSVUpload: PropTypes.bool,
  hideDownloadTemplateButton: PropTypes.bool,
  templateConfig: PropTypes.object,
  tenantUploadConfig: PropTypes.object,
  customButtons: PropTypes.any,
  jsonUpload: PropTypes.bool,
  uploadInstructions: PropTypes.string,
  acceptFileTypes: PropTypes.string
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadHandler);
