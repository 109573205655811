import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import TagSuggestionSection from "./TagSuggestionSection";

const TextInputFieldSection = ({
  type,
  comment,
  setComment,
  saveComment,
  closeComment,
  editCommentTextInput,
  setEditCommentTextInput,
  setCommentEditable,
  selectedComment,
  editCommentFn,
  classes,
  setUserMentioned,
  usersMentioned,
  placeholder = ""
}) => {
  const [hasSuggestions, setHasSuggestions] = useState(false);

  useEffect(() => {
    if (type === "edit") {
      document.getElementById("editTextBoxField").innerHTML = ` ${editCommentTextInput} &nbsp;`;
    }
  }, [type]);

  const handleCommentInputChange = (e,type) => {
    type === "edit" ? setEditCommentTextInput(e.currentTarget.innerHTML) : setComment(e.currentTarget.innerText);
    if (e.nativeEvent.data.match(/@$/)) {
      setHasSuggestions(true);
    }
  }
  
  return (
    <React.Fragment>
      <div>
        <div
          id={type=== "create" ? "createTextBoxField" : type === "reply" ? "replyTextBoxField" : "editTextBoxField"}
          role="textbox"
          contenteditable="true"
          aria-expanded="true"
          onInput={(e) => handleCommentInputChange(e, type) }
          className={classes.textInputField}
          data-placeholder={placeholder}
        >
        </div>
        {hasSuggestions && (
          <TagSuggestionSection
            comment={type=== "edit" ?  editCommentTextInput : comment}
            hasSuggestions={hasSuggestions}
            setHasSuggestions={setHasSuggestions}
            classes={classes}
            type={type}
            setUserMentioned={setUserMentioned}
            usersMentioned={usersMentioned}
          />
        )}
        <div className={classes.actionBtn}>
          <Button
            id="cancelButton"
            color="primary"
            disabled={type=== "edit" ? !editCommentTextInput : !comment}
            onClick={() => type=== "edit" ?  setCommentEditable(false) : closeComment(type)}
          >
            Cancel
          </Button>
          <Button
            id="submitButton"
            color="primary"
            disabled={type=== "edit" ?  !editCommentTextInput : !comment}
            variant="contained"
            onClick={() => type=== "edit" ? editCommentFn(selectedComment) : saveComment() }
          >
            Submit
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TextInputFieldSection;
