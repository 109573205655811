import { DATE_FORMATS } from "config/constants";
import { isString } from "lodash";
// import { attributeFormatter } from "modules/assortsmart/utils-assortsmart/utilityFunctions";
import moment from "moment";
import { Link } from "react-router-dom";

const nf = new Intl.NumberFormat("en-US");

export const attributeFormatter = function (value) {
  //isPrice key is used for inserting dollar in value formatter

  //If value is not a valid type, return empty string
  if (!value) {
    return "";
  }

  //If value is not a numerical type, return by camel casing the text
  let letter = /[a-zA-Z]/g;
  if (value.match(letter)) {
    return value.length < 4
      ? value
          .replace(" penetration", "")
          .split("_")
          .map((setAllfield) => {
            //If setAll field contains term "cc",e.g max_cc whole term needs to be converted to uppercase
            if (setAllfield === "cc" || setAllfield === "aur")
              return setAllfield.toUpperCase();
            return (
              setAllfield.charAt(0).toUpperCase() +
              setAllfield.slice(1).toUpperCase() +
              " "
            );
          })
          .join("")
      : value
          .replace(" penetration", "")
          .split("_")
          .map((setAllfield) => {
            //If setAll field contains term "cc",e.g max_cc whole term needs to be converted to uppercase
            if (setAllfield === "cc" || setAllfield === "aur")
              return setAllfield.toUpperCase() + " ";
            return (
              setAllfield.charAt(0).toUpperCase() + setAllfield.slice(1) + " "
            );
          })
          .join("");
  }

  //If value is in numerical format
  return value
    .replace(" penetration", "")
    .replace("_", " ")
    .split("_")
    .map((e) => {
      let temp = e.split(" ");
      temp[0] = parseFloat(temp[0]) >= 0 ? parseInt(temp[0]).toString() : "";
      temp[1] = parseFloat(temp[1]) >= 0 ? parseInt(temp[1]).toString() : "";
      return temp[1].length ? temp[0] + " - " + temp[1] : temp[0];
    })
    .join("");
};

export const apostropheHandler = (inputPayload) => {
  //escaped characters on the UI to unescaped on the postgres DB
  var newVal;
  if (typeof inputPayload === "string") {
    newVal = unescape(inputPayload.replace(/'/g, "''"));
    inputPayload = newVal;
  }
  return inputPayload;
};

export const numbersWithComma = (
  value,
  isDecimal,
  isRoundedOfftoThreeDecimals,
  disableCommaFormatting = false
) => {
  //isDecimal key is used to return value with decimals
  if (value?.value === "-" || disableCommaFormatting) {
    return value?.value; // must return "-" instead of NaN hence adding the condition (it expects a hypen or a num)
  } else if (value?.value) {
    if (Number.isInteger(value?.value)) {
      let tempValue =
        parseInt(value?.value) > -1 ? Math.abs(value?.value) : value?.value;
      return nf.format(tempValue);
    } else {
      let replaceCommaValue = (value?.value).toString().replace(",", "");
      let tempValue =
        parseFloat(replaceCommaValue) > -1
          ? Math.abs(replaceCommaValue)
          : replaceCommaValue;
      if (isDecimal) {
        return nf.format(Number.parseFloat(tempValue).toFixed(2));
      } else if (isRoundedOfftoThreeDecimals) {
        return nf.format(Number.parseFloat(tempValue).toFixed(3));
      } else {
        return nf.format((Math.round(tempValue * 100) / 100).toFixed(0));
      }
    }
  } else if (value?.value === 0) {
    return value?.value;
  } else {
    return value?.value || "";
  }
};

export const dollarFormatter = function (value, isDollarWithDecimal) {
  //Function adds dollar sign in front of value
  //isDollarWithDecimal is used return value with dollar sign in front and with 2 decimal point

  if (typeof value.value == Number || !isNaN(Number(value.value))) {
    let valsfor;
    let toFix = isDollarWithDecimal ? 2 : 0;
    const cellData = value.value;
    const toVal = Math.abs(value.value);
    if (toVal < 1 && toVal > -1) {
      valsfor = toVal.toFixed(2);
    } else {
      valsfor = isDollarWithDecimal ? toVal : Math.round(toVal);
    }
    let newvalsfor = nf.format(Math.abs(Number(valsfor)).toFixed(toFix));
    if (cellData > -1) {
      return "$" + newvalsfor;
    } else {
      return "-$" + newvalsfor;
    }
  } else {
    // Need to test this on other screens, please let me know if this change is breaking on any screens,
    return value?.value
      ? value?.value
      : value?.cell?.row?.subRows?.length > 0
      ? ""
      : "-";
  }
};

export const percentFormatter = function (value, twoDecimals, dontMultiply) {
  //Used to multiply the given value by 100 and add percentage sign... "dontMultiply" key is used when we dont need the value to be multiplied by 100
  if (value && value.value && !isNaN(Number(value.value))) {
    let cellData;
    let multiplier = dontMultiply ? 1 : 100;
    let toFix = twoDecimals ? 2 : 0;
    if (typeof value.value == Number || !isNaN(Number(value.value))) {
      cellData = value.value;
      let newVal = (cellData * multiplier).toFixed(toFix);
      if (Math.abs(Number(newVal)) === 0) {
        newVal = 0;
      }
      return newVal + "%";
    } else {
      return value.value;
    }
  } else if (value.value === 0) {
    return value.value + "%";
  } else {
    return "-";
  }
};

export const decimalsFormatter = function (
  x,
  isDecimal,
  isRoundedOfftoThreeDecimals
) {
  //Function returns values with 2 decimal point
  //isDecimal key is  false used to return round off value without decimals
  if (isRoundedOfftoThreeDecimals) return parseFloat(x.value).toFixed(3);
  if (x.value === 0) {
    return 0;
  } else if (x.value > 0) {
    if (!isDecimal) {
      return parseInt((Math.round(x.value * 100) / 100).toFixed());
    }

    // can be rounded off to n decimal places, by default 2
    let roundOffTo = x?.colDef?.extra?.roundOffTo || 2;

    return parseFloat((Math.round(x.value * 100) / 100).toFixed(roundOffTo));
  } else {
    if (!x.value) {
      return 0;
    }
    if (!isDecimal && x.value < 0) {
      return parseInt((Math.round(x.value * 100) / 100).toFixed());
    }
    if (
      isDecimal &&
      x.value < 0 &&
      (x.is_negative_value_allowed || !x.is_negative_value_allowed)
    ) {
      return parseFloat((Math.round(x.value * 100) / 100).toFixed(2));
    }
    return parseFloat(x.value);
  }
};

export const capitalize = (string) => {
  //Function used to capitalize the first letter
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const capitalizeEachLetter = (string) => {
  //Function used to capitalize the first letter of each sting
  var splitStr = string.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const formattedDate = (date, format) => {
  return date && moment(date, DATE_FORMATS).isValid()
    ? moment(date, DATE_FORMATS).format(format)
    : "-";
};

export const arrayToCommaFormatter = (value) => {
  return value?.length ? value.join(", ") : value;
};

export const setAllLabelFormatter = (value) => {
  //It removes "_ty" from the value and passes to attribute formatter
  return attributeFormatter(value ? value.replace("_ty", "") : "");
};

export const fileLabelFormatter = (value) => {
  const fileNameSplit = value.split(".");
  let extension = "";
  if (fileNameSplit.length > 1) {
    extension = fileNameSplit.pop();
  }
  const fileName = value.substr(0, value.lastIndexOf("."));
  switch (extension) {
    case "xlsx":
    case "csv":
      return (
        <span>
          <i className="fa fa-file-excel-o fa-lg"></i> {fileName}
        </span>
      );
    case "pdf":
      return (
        <span>
          <i className="fa fa-file fa-lg"></i> {fileName}
        </span>
      );
    case "doc":
    case "txt":
      return (
        <span>
          <i className="fa fa-file-text fa-lg"></i> {fileName}
        </span>
      );
    case "":
      const currentLocation = window.location;
      const currentPath = currentLocation.pathname;
      return (
        <span>
          <i className="fa fa-folder fa-lg" aria-hidden="true"></i>
          <Link to={`${currentPath}/${value}`}>{value}</Link>
        </span>
      );
    default:
      return (
        <span>
          <i className="fa fa-file-o fa-lg"></i> {fileName}
        </span>
      );
  }
};

/**
 *
 * @param { size of the product } size
 * @returns size if size is not null or empty. Otherwise it returns N/A
 */
export const productSizeFormatter = (size) => {
  return size ? size : "N/A";
};

export const dataParser = (type, data) => {
  //Based on the type formats the data and returns
  switch (type) {
    case "float":
      return parseFloat(data || 0);
    case "int":
      return parseInt(data || 0);
    case "textToNum":
      return Math.round(data?.toString()?.replaceAll(",", "") || 0);
    case "textToNumWithDecimal":
      return parseFloat(data?.toString()?.replaceAll(",", "") || 0);
    default:
      return data;
  }
};

/**
 *
 * @param {String} channel - channel arg is of string type
 * @returns boolean - whether channel is Ecomm or wholesale or not
 */
export const isEcommOrWholeSale = (channel) => {
  return channel === "Ecom" || channel === "Wholesale";
};

export const replaceCharacter = (str, oldChar, newChar) => {
  return str.replace(oldChar, newChar);
};

export const groupByCustom = ({ Group: array, By: props }) => {
  const getGroupedItems = (item) => {
    let returnArray = [];
    for (let data of props) {
      returnArray.push(item[data]);
    }
    return returnArray;
  };

  let groups = {};
  if (array)
    for (let data of array) {
      const arrayRecord = data;
      const group = JSON.stringify(getGroupedItems(arrayRecord));
      groups[group] = groups[group] || [];
      groups[group].push(arrayRecord);
    }
  return Object.keys(groups).map((group) => {
    return groups[group];
  });
};

/**
 * adds plural character 's' based on count sent
 * @param {String} input - string Ex. User, Product
 * @param {int} count - count of string type
 * @returns String - returns singular or plural string
 */
export const getInputNoun = (input, count) => {
  return `${input}${count > 1 ? "s" : ""}`;
};

/**
 *
 * @param {str} string
 * @returns str
 *
 * This function captializes the string, i.e It will make first letter capital and rest of chars as
 * lower case, only if the first character is strictly lower case else it will return back the string
 */
export const captializeStringIfCamelCase = (string) => {
  const firstChar = string.charAt(0);
  if (firstChar === firstChar.toUpperCase()) {
    return string;
  }
  return capitalize(string);
};

/**
 *
 * @param {string or Array} listOrStr
 * @returns lower case values
 * This function takes in list or String as an argument
 * and converts those values to lower case. If the function
 * receives any other data structure/value, it will return
 * the value as it is without making any changes
 */
export const mapConvertToLowerCase = (listOrStr) => {
  if (Array.isArray(listOrStr)) {
    return listOrStr.map((element) => element.toLowerCase());
  }
  if (isString(listOrStr)) return listOrStr.toLowerCase();
  return listOrStr;
};
