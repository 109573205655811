export const notificationsRoutes = {
  home: "/events",
};

export const storeGrouping = {
  home: "/store-grouping",
  createGroup: "/store-grouping/create-group",
  viewGroup: "/store-grouping/view",
  modifyGroup: "/store-grouping/modify",
};

export const userManagementRoutes = {
  user: "/user-management",
  role: "/user-management/user-role-management",
};