import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";

import Loader from "Utils/Loader/loader";
import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";

import { useStyles as sharedStyles } from "./styles-tenant-user-mgmt";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.typography.pxToRem(15),
    justifyContent: "center",
  },
}));
const AccessibleHierarchyModal = (props) => {
  const [accessibleDeptTableColumns, setAccessibleDeptTableColumns] = useState(
    []
  );
  const [accessibleDeptTableData, setAccessibleDeptTableData] = useState([]);

  const sharedClasses = sharedStyles();
  const classes = useStyles();

  useEffect(() => {
    const getInitialData = async () => {
      let configColumns = await props.getTableConfig();
      let formattedColumns = agGridColumnFormatter(configColumns.data?.data);
      setAccessibleDeptTableColumns(formattedColumns);
    };
    getInitialData();
  }, []);

  useEffect(() => {
    if (props.tableData && props.tableData.length > 0) {
      setAccessibleDeptTableData(props.tableData);
    }
  }, [props.tableData]);

  return (
    <Dialog
      className={sharedClasses.root}
      maxWidth={"sm"}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="customized-dialog-title">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          View Department Mapping
        </Grid>
      </DialogTitle>
      <DialogContent
        classes={{
          root: sharedClasses.content,
        }}
      >
        <Loader loader={accessibleDeptTableColumns.length > 0 ? false : true}>
          <div className={sharedClasses.contentBody}>
            <Paper elevation={0}>
              {accessibleDeptTableColumns.length > 0 && (
                <AgGridComponent
                  rowdata={accessibleDeptTableData}
                  columns={accessibleDeptTableColumns}
                  uniqueRowId={"id"}
                  sizeColumnsToFitFlag
                />
              )}
            </Paper>
          </div>
        </Loader>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.footer,
        }}
      >
        <Button
          onClick={props.closeModal}
          id="createStoreCancelBtn"
          color="primary"
          variant="outlined"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccessibleHierarchyModal;
