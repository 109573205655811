import React, { Component, Suspense, lazy } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { POSTHOG_API } from "./config/api";
import { POSTHOG_KEY } from "./config/constants";
import posthog from "posthog-js";
import Login from "./commonComponents/auth";
import store from "./store";
import "react-dates/initialize";
import "font-awesome/css/font-awesome.min.css";
//Authentication Testing - Firebase Connectivity
import "firebaseui/dist/firebaseui.css";
import Home from "./commonComponents/home";
import NotFound from "./commonComponents/notFound/NotFound";
import ConfirmPrompt from "./commonComponents/confirmPrompt";
import EmailLogin from "./commonComponents/auth/emaillogin";
import MetaDataComponent from "./hoc/metadata";
import ProtectedRoute from "./commonComponents/layout/ProtectedRoute";
import Snackbar from "./Utils/snackbar/components/snackbar";
import SnackbarProvider from "./Utils/snackbar";
import Skeleton from "@mui/material/Skeleton";
import { init as initApm } from "@elastic/apm-rum";
import { apmRumConfig } from "./config/index";
// import { authConfig } from "commonComponents/auth/config";

const CoreLayout = lazy(() => import("./commonComponents/core-layout"));
const DataIngestionRoute = lazy(() =>
  import("./modules/dataIngestion/routes-dataIngestion"))
const MlopsRoute = lazy(() =>
  import("./modules/mlops/routes-mlops"))

const ProductivityHelperRoute = lazy(() =>
  import("./modules/productivityHelper/routes-productivityHelper"))

// const apm = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  // serviceName: apmRumConfig.configName,

  // Set custom APM Server URL (default: http://localhost:8200)
  // serverUrl: apmRumConfig.configHost,
  // serviceVersion: "",
// });

class App extends Component {
  constructor() {
    super();
    // posthog.init(POSTHOG_KEY, { api_host: POSTHOG_API });

    this.state = {
      app: "",
      confirm: false,
      confirmCallback: null,
    };
  }
  selectApp = (app) => {
    this.setState({ app });
  };

  // setConfirm, getConfirmation and ConfirmPrompt are used to show confirm prompt
  setConfirm = () => {
    this.setState({ confirm: false });
  };
  getConfirmation = (_, confirmCallback) => {
    this.setState({ confirm: true, confirmCallback });
  };

  componentDidMount() {
    //creating a script object for whatfix
    // const script = document.createElement("script");

    // script.src = authConfig.whatFixUrl;
    // script.async = true;
    // script.type = "text/javascript";
    // script.language = "javascript";
    //appending this script tag will give us the Self Help button
    // document.head.appendChild(script);
  }

  render() {
    return (
      <>
        <Provider store={store}>
          <SnackbarProvider>
            <Router getUserConfirmation={this.getConfirmation}>
              {this.state.confirm && (
                <ConfirmPrompt
                  confirmCallback={this.state.confirmCallback}
                  setConfirm={this.setConfirm}
                />
              )}
              <Snackbar />
              <MetaDataComponent>
                <div className="App">
                  <Suspense
                    fallback={
                      <div>
                        {" "}
                        <Skeleton> </Skeleton>
                      </div>
                    }
                  >
                    <Switch>
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/emaillogin" component={EmailLogin} />
                      <ProtectedRoute exact path={`/home`} component={Home} />
                      <ProtectedRoute
                        path={`/data-platform/`}
                        component={DataIngestionRoute}
                      />
                      <ProtectedRoute
                        path={`/mlops/`}
                        component={MlopsRoute}
                      />
                       <ProtectedRoute
                        path={`/productivityHelper`}
                        component={ProductivityHelperRoute}
                      />
                      <Redirect exact from="/" to="/login" />
                      <ProtectedRoute component={CoreLayout} />
                      <ProtectedRoute exact path="/*" component={NotFound} />
                    </Switch>
                  </Suspense>
                </div>
              </MetaDataComponent>
            </Router>
          </SnackbarProvider>
        </Provider>
      </>
    );
  }
}

export default App;
