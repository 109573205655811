import { SET_TICKET} from '../actions/types';

const initialState = {
  tickets: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TICKET:
      return {
        ...state,
        tickets: action.payload,
      };
    default:
      return state;
  }
}
