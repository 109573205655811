import React from "react";
import PropTypes from "prop-types"; // for our bonus, see below
// eslint-disable-next-line
import ReactSelect, { components } from "react-select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { isStringIncluded } from "../functions/utils";
import "./index.css";
import muiTheme from "Styles/theme";
import { Tooltip } from "@mui/material";
import { uniqBy } from "lodash";

const ValueContainer = ({ children, selectProps, ...props }) => {
  const { getValue, hasValue } = props;
  const { inputValue } = selectProps;
  const values = getValue();
  return (
    // to handle for multiple selections later as the list can have large number of values
    <components.ValueContainer {...props}>
      {children[1]}
      {!hasValue && children[0]}
      {hasValue && inputValue === "" && (
        <Tooltip title={values[0].label}>
          <span className="valueStyle">{values[0].label}</span>
        </Tooltip>
      )}
      {hasValue && inputValue === "" && values.length !== 1 && (
        <span className="counterStyle">+{selectProps.value.length - 1}</span>
      )}
    </components.ValueContainer>
  );
};

/**
 * @func
 * @desc Return items as true which passes the test scenario
 * @param {Object} item
 * @param {String} input
 * @returns {Boolean}
 */
const filterOptions = (item, input) => {
  if (input) {
    const filterKeys = input.split(",");
    let isOptionTrue = false;
    filterKeys.forEach((seacrhVal) => {
      if (isStringIncluded(seacrhVal, item.label)) {
        isOptionTrue = true;
      }
    });
    return isOptionTrue;
  }
  return true;
};

/**
 *
 * @param {object} props //props object of react select
 * This function is called when there is no search term present and user clicks on select all/unselect all
 */
const selectAllOrUnSelectAllOptions = (props) => {
  //We compare options length and selected values length
  //If both are same, we treat it as unselect all and make the selections empty array
  if (
    props.options.length ===
    (props.selectProps.value && props.selectProps.value.length)
  ) {
    props.setValue([]);
  } else {
    //else we treat it as select all and make the selection equal to options
    props.setValue(props.options);
  }
};

/**
 *
 * @param {object} props //props of react select
 * @param {array} currentSearchData //current filtered data based on search term
 * This function is called when the user clicks on select/unselect all when there is a
 * search term applied
 */
const selectAllOrUnSelectAllFilteredOptions = (props, currentSearchData) => {
  //Check if there is any prior selections
  //If there are any selections, it would come as array
  //Else it would be empty string
  const selectedValues = Array.isArray(props.selectProps.value)
    ? props.selectProps.value
    : [];

  //This variable stores the values of selected values except the current filtered data selections
  const filteredValues = selectedValues.filter((filter) => {
    return (
      currentSearchData.findIndex(
        (searchFilter) => searchFilter.value === filter.value
      ) === -1
    );
  });
  //This variable stores the values of selected values that are present in the current filtered data selections
  const currentSearchSelections = selectedValues.filter((filter) => {
    return (
      currentSearchData.findIndex(
        (searchFilter) => searchFilter.value === filter.value
      ) > -1
    );
  });

  //If the current search selections are all present in the current filtered data,
  //we treat it as unselect all of those current search filtered data
  if (currentSearchSelections.length === currentSearchData.length) {
    props.setValue(filteredValues);
  } else {
    //Else we append the current filtered options of search term to the existing selected values
    const newSelectedOptions = uniqBy(
      [...props.selectProps.value, ...currentSearchData],
      "value"
    );
    props.setValue(newSelectedOptions);
  }
};

/**
 *
 * @param {object} props //props of react select
 */
const onSelectOrUnSelectAll = (props) => {
  //This variable will help us know if there is search term present or not
  const currentSearchData = props.options.filter((filter) =>
    filterOptions(filter, props.selectProps.inputValue)
  );
  //If there is no search applied, we do select all and unselect all based on all option values
  if (currentSearchData.length === props.options.length) {
    //helper function for all options
    selectAllOrUnSelectAllOptions(props);
  } else {
    //else if there is a search term, we apply select all and unselect all based on filtered values
    //helper function for filtered options
    selectAllOrUnSelectAllFilteredOptions(props, currentSearchData);
  }
};
const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {props.isMulti && !!props.options.length && (
        <div
          className="select-all"
          onClick={() => {
            onSelectOrUnSelectAll(props);
          }}
        >
          Select/Unselect All
        </div>
      )}
      {props.children}
    </components.MenuList>
  );
};
const customValueStyle = (provided) => {
  const position = "relative";

  return { ...provided, position };
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

const Select = (props) => {
  const [pageIndex, setPageIndex] = React.useState(2);
  const [options, setOptions] = React.useState([]);
  const onMenuScrollToBottom = (_event) => {
    if (props.pagination) {
      props.fetchOptions(pageIndex);
      setPageIndex(pageIndex + 1);
    }
    if (props?.options?.length > 50) {
      setOptions(props.options.slice(0, options.length + 50));
    }
  };
  React.useEffect(() => {
    // if (props?.options?.length > 50) {
    //   setOptions(props.options.slice(0, 50));
    // } else {
    //   setOptions(props.options);
    // }
    setOptions(props.options);
  }, [props.options]);

  return (
    <ReactSelect
      {...props}
      menuPlacement={"auto"}
      options={options}
      hideSelectedOptions={false}
      components={{ ValueContainer, MenuList, DropdownIndicator }}
      closeMenuOnSelect={props.isMulti ? false : true}
      // onMenuScrollToBottom={onMenuScrollToBottom}
      styles={{
        valueContainer: (provided) => {
          return props.valueStyles
            ? props.valueStyles
            : customValueStyle(provided);
        },
        menuPortal: (provided) => ({
          ...provided,
          zIndex: "9999",
        }),
        menu: (provided, _state) => ({
          ...provided,
          zIndex: "9999",
          marginTop: "0.75rem",
        }),
        indicatorSeparator: (_provided, _state) => ({
          display: "none",
        }),
        indicatorContainer: (_provided, _state) => ({
          display: "flex",
          padding: "0 5px",
        }),
        dropdownIndicator: (provided, _state) => ({
          ...provided,
          padding: "0",
        }),
        control: (provided, _state) => {
          return { ...provided, minHeight: "2.125rem", lineHeight: "normal" };
        },
      }}
      filterOption={filterOptions}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: muiTheme.palette.primary.lighter,
          primary: muiTheme.palette.primary.main,
        },
      })}
    />
  );
};

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

Select.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default Select;
