import LoadingOverlay from "../../Utils/Loader/loader";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { ApmRoute } from "@elastic/apm-rum-react";
// import { setActiveScreenName } from "modules/assortsmart/services-assortsmart/common-assort-service";
import { addSnack } from "actions/snackbarActions";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <ApmRoute
      {...rest}
      render={(props) => {
        if (rest.isTenantFetchFailed) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
        if (rest.isAuthenticated) {
          const allProps = { ...props, ...rest };
          // allProps.setActiveScreenName(allProps.screenName);
          return <Component {...allProps} />;
        } else {
          if (rest.isTokenVerified) {
            rest.addSnack({
              message: "Please Login to continue...",
              options: {
                variant: "error",
                doNotHide: true,
                autoHideDuration: 1500,
              },
            });
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            return (
              <LoadingOverlay
                loader={true}
                spinner
                text={rest.loaderText}
              ></LoadingOverlay>
            );
          }
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    isTokenVerified: state.authReducer.isTokenVerified,
    isTenantFetchFailed: state.authReducer.isTenantFetchFailed,
    loaderText: state.authReducer.alertProperties.Text,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setActiveScreenName: (data) => dispatch(setActiveScreenName(data)),
    addSnack: (snackMsg) => dispatch(addSnack(snackMsg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
