import PropTypes from "prop-types";
import { useEffect } from "react";
import ConfigurationsTable from "./configurationsTable";
import Typography from "@mui/material/Typography";
import CreateFilter from "./createFilter";
import { Container } from "@mui/material";
import globalStyles from "Styles/globalStyles";


const Header = (props) => {
  const globalClasses = globalStyles();

  return (
    <div className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}>
      <Typography variant="h4">Configurations</Typography>
      <CreateFilter id={props.id} />
    </div>
  );
};
const Configuration = (props) => {
  const id = props.id;
  useEffect(() => {
    //When there is a change in ID, we fetch table data from the API
  }, [id]);
  return (
    <>
      <Container maxWidth={false}>
        <Header id={id} />
        <ConfigurationsTable id={id} />
      </Container>
    </>
  );
};

Configuration.propTypes = {
  id: PropTypes.any.isRequired,
};

export default Configuration;
