import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import React, { useEffect, useState } from "react";
import globalStyles from "Styles/globalStyles";
import { makeStyles } from "@mui/styles";
import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import Select from "Utils/select";
import { sampleViewFields } from "modules/dataIngestion/constants-dataIngestion/sampleViewCols";
import { getSampleDataView } from "modules/dataIngestion/services-dataIngestion/dataIngestion-combined-service";
import { connect } from "react-redux";
import { addSnack } from "../../../../actions/snackbarActions";
import Loader from "Utils/Loader/loader";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "1rem",
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  cardContent: {
    paddingBottom: "16px !important",
  },
  form: {
    // alignItems: "flex-end",
    // display: "flex",
    width: "100%",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  resetButton: {
    marginLeft: "1rem",
  },
  accordion: {
    border: "none",
    width: "100% !important",
    "&::before": {
      background: "none",
    },
  },
  subHeader: {
    // color: "#758498",
    // marginBottom: '1rem',
    fontWeight: "500",
    fontSize: "0.937rem",
    lineHeight: 1.4375,
    letterSpacing: 0,
    fontFamily: "Poppins,Roboto,Helvetica,Arial,sans-serif",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  filterButton: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ViewModal = (props) => {
  const globalClasses = globalStyles();
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState({
    label: "Columns",
    value: "columns",
  });
  const [propertiesList, setPropertiesList] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [columns, setColumns] = useState([]);
  const [sampleDataTab, setSampleDataTab] = useState("Properties");
  const [sampleData, setSampleData] = useState({});

  useEffect(async () => {
    try {
      let payload = {
        view: props.selectedView?.view,
        table: props.selectedView?.table,
        connector: props.selectedView?.connector,
        source_config: props.selectedView?.source_config,
        field_delimiter: props.selectedView?.field_delimiter || "",
      };
      setLoader(true);
      let response = await props.getSampleDataView(payload);
      if (response?.data?.status == "failed") {
        setLoader(false);
        props.addSnack({
          message: response?.data?.message,
          options: {
            variant: "error",
          },
        });
      } else {
        setSampleData(response.data);
        setSampleDataTab("Properties");
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      props.addSnack({
        message: "Failed to fetch sample data",
        options: {
          variant: "error",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (sampleDataTab === "Data") {
      let data = sampleData?.data;
      if (data && data.length ) {
        let cols = Object.keys(data[0]).map((key) => {
          return {
            label: (key.charAt(0).toUpperCase() + key.slice(1))
              .split("_")
              .join(" "),
            column_name: key,
            dimension: "Product",
            type: "str",
          };
        });
        setColumns(agGridColumnFormatter(cols, null, null, false));
        setRowData(
          [...data].map((col,index) => {
            return { key: col[Object.keys(col)[0]]+index , ...col };
          })
        );
      } 
    //   else {
    //     props.addSnack({
    //       message: "Failed to fetch sample data",
    //       options: {
    //         variant: "error",
    //       },
    //     });
    //   }
    } else {
      setColumns(
        agGridColumnFormatter(sampleViewFields["columns"], null, null, false)
      );
      setPropertiesList([
        { label: "Columns", value: "columns" },
        { label: "Indexes", value: "indexes" },
        { label: "Partition", value: "partition" },
      ]);
      sampleData?.schema_info?.column_datatypes && setRowData(
        sampleData?.schema_info?.column_datatypes.map((col) => {
          return { key: col.column_name, ...col };
        })
      );
    }
  }, [sampleDataTab, sampleData]);

  const onChangeHandler = (selectedData) => {
    setColumns(
      agGridColumnFormatter(
        sampleViewFields[selectedData?.value],
        null,
        null,
        false
      )
    );
    let rowDataInfo =
      selectedData.value === "columns"
        ? sampleData?.schema_info?.column_datatypes && sampleData?.schema_info?.column_datatypes.map((col) => {
            return { key: col.column_name, ...col };
          })
        : selectedData.value === "partition"
        ? sampleData?.schema_info?.partition_key && sampleData?.schema_info?.partition_key.map((col) => {
            return { key: col, partition_name: col };
          })
        : sampleData?.schema_info?.index && sampleData?.schema_info?.index.map((col) => {
            return { key: col.index_name, ...col };
          });
    setRowData(rowDataInfo ? [...rowDataInfo] : []);
    setSelectedOption({ ...selectedData });
    setLoader(false);
  };

  const handleConfiguration = (event, newSelection) => {
    if (newSelection !== null) {
      setColumns([]);
      setRowData([]);
      setSelectedOption({ label: "Columns", value: "columns" });
      setSampleDataTab(newSelection);
    }
  };

  return (
    <ConfirmPrompt
      showModal={true}
      title={"View"}
      message=""
      ariaLabeledBy="addUser-dialog"
      primaryBtnText="Save"
      secondaryBtnText="Cancel"
      showCloseIcon={true}
      size="lg"
      hideActionFooter={true}
      confirmCallback={(val) => {
        if (!val) {
          props.setViewModal(false);
        }
      }}
    >
      <div>
        <div
          className={`${globalClasses.flexRow} ${globalClasses.centerAlign} ${globalClasses.marginTop} ${globalClasses.marginBottom}`}
        >
          <ToggleButtonGroup
            value={sampleDataTab}
            exclusive
            onChange={handleConfiguration}
            aria-label="text alignment"
            size="small"
          >
            {["Properties", "Data"].map((config) => {
              return (
                <ToggleButton value={config} aria-label="centered">
                  {config}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </div>
        <Loader loader={loader}>
          {sampleDataTab !== "Data" && (
            <span
              style={{ width: "40%", display: "block", marginBottom: "1rem" }}
            >
              <Select
                label=""
                is_multiple_selection={false}
                filter_keyword="matchDropdown"
                options={propertiesList}
                value={selectedOption}
                isClearable={false}
                isDisabled={false}
                onChange={(props) => {
                  setLoader(true);
                  onChangeHandler(props);
                }}
              />
            </span>
          )}
          <div style={{ margin: "1px" }}>
            {
              <AgGridComponent
                columns={columns}
                rowdata={rowData}
                sizeColumnsToFitFlag={true}
                uniqueRowId={"key"}
              />
            }
          </div>
        </Loader>
      </div>
    </ConfirmPrompt>
  );
};

const mapStateToProps = (store) => {
  return {};
};

const mapActionsToProps = {
  addSnack,
  getSampleDataView,
};

export default connect(mapStateToProps, mapActionsToProps)(ViewModal);
