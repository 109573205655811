import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";

export const getFiscalWeekAndYear = (
  year,
  weekNum,
  fiscalCalendarDetails,
  isOnlyWeek
) => {
  let fiscalWeeks = fiscalCalendarDetails && fiscalCalendarDetails[year];
  if (isEmpty(fiscalWeeks)) return null;

  return isOnlyWeek
    ? fiscalWeeks[weekNum] && fiscalWeeks[weekNum].fw
    : {
        week: fiscalWeeks[weekNum] && fiscalWeeks[weekNum].fw,
        year: fiscalWeeks[weekNum] && fiscalWeeks[weekNum].fy,
      };
};

export const getFiscalWeekArray = (
  startDate,
  endDate,
  fiscalCalendarDetails
) => {
  // newSDate is start date of the particular week, currently in focus now
  let newSDate = cloneDeep(startDate);
  // newEDate initially stores start, later the date get incremented according fiscal dates

  let newEDate = cloneDeep(startDate);
  let weekNumList = [];
  let yearList = [];

  while (moment(newEDate).isBefore(endDate, "day")) {
    let fiscalData = getFiscalWeekAndYear(
      moment(newSDate).year(),
      moment(newSDate).week(),
      fiscalCalendarDetails
    );
    if (fiscalData?.week === null) break;
    weekNumList.push(parseInt(fiscalData?.week));
    yearList.push(parseInt(fiscalData?.year));
    newEDate = moment(newSDate).add(6, "d");
    newSDate = moment(newSDate).add(7, "d");
  }
  return { weekNumList, yearList };
};
