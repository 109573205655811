import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Checkbox,
} from "@mui/material";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import { cloneDeep, isEmpty } from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import globalStyles from "Styles/globalStyles";
import { collectiveTypes, matchDropdown } from "./constants";
import { Select } from "commonComponents/filters/Select";
import Form from "Utils/form";
import AddIcon from "@mui/icons-material/Add";
import { addSnack } from "actions/snackbarActions";
import {
  setSaveSearchConfig,
  setLastSearchType,
} from "actions/tableColumnActions";
import { parseRangeBody } from "./table-functions";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const useStyles = makeStyles((theme) => ({
  boxPadding: {
    padding: "1.5rem 0  !important",
  },
  clearSeacrhBtn: {
    marginLeft: "auto",
  },
  advanceOptionDropdown: {
    display: "inline-block",
    marginInline: theme.spacing(2),
    width: "7rem",
  },
  searchDialogContent: {
    minHeight: "10rem",
  },
  dialogContentHeaderText: {
    ...theme.typography.body1,
    color: theme.palette.action.disabled,
  },
  matchCheckbox: {
    paddingLeft: 0,
  },
  noDataContainer: {
    display: "grid",
    justifyItems: "center",
    alignItems: "center",
  },
}));

const AgGridSearch = (props) => {
  const classes = useStyles();
  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [searchType, setSearchType] = useState(0);
  const [searchTypeToUpdate, setSearchTypeToUpdate] = useState(0);
  const [columnsList, setColumnsList] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const clearBtnRef = useRef();
  const [flag_edit, setFlag_edit] = useState(false);
  const { lastSearchTab } = useSelector((store) => store?.tableReducer);
  const dispatch = useDispatch();

  // set last search type to null when switching to new page or table destroyed
  useEffect(() => {
    return () => {
      dispatch(setLastSearchType(null));
    };
  }, []);

  /**
   * @func
   * @desc Seggregate all searchable columns
   */
  useEffect(() => {
    const columns = [];
    props.columnApi.getAllGridColumns().forEach((item) => {
      item.colDef.is_searchable && item.visible && columns.push(item.colDef);
    });
    if (lastSearchTab > -1) {
      // update store state when search applied and use the same state as the current activetab once opened
      setSearchType(lastSearchTab);
    } else {
      setSearchType(props.advancedSearchConfig?.tab_code || 0);
    }
    setColumnsList(columns);
  }, [showSearchDialog]);

  /**
   * @func
   * @desc Prep props for tabs
   * @param {Number} index
   * @returns
   */
  const tabProps = (index) => {
    return {
      id: `search-tab-${index}`,
      "aria-controls": `search-tabpanel-${index}`,
    };
  };

  return (
    <>
      <Box
        className={`${classes.boxPadding} ${"ag-column-select-header"}`}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          id="searchModal"
          variant="contained"
          color="primary"
          startIcon={<SearchOutlinedIcon />}
          onClick={() => {
            setShowSearchDialog(true);
          }}
        >
          Search
        </Button>
      </Box>
      <ConfirmPrompt
        showModal={showSearchDialog}
        message=""
        title="Search"
        ariaLabeledBy="agGrid-search-dialog"
        size="md"
        showCloseIcon={false}
        primaryBtnText=""
        secondaryBtnText=""
      >
        <Tabs
          value={searchType}
          onChange={(e, val) => {
            if (flag_edit) {
              setShowPrompt(true);
              setSearchTypeToUpdate(val);
            } else {
              setSearchType(val);
              setSearchTypeToUpdate(val);
            }
          }}
          aria-label="search-tabs"
          textColor="primary"
        >
          <Tab label="Search" {...tabProps(0)} />
          <Tab label="Advance Search" {...tabProps(1)} />
          <Button
            id="clearSearch"
            variant="text"
            className={classes.clearSeacrhBtn}
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={clearBtnRef}
          >
            Clear Filter
          </Button>
        </Tabs>
        <Search
          setShowSearchDialog={setShowSearchDialog}
          gridApi={props.api}
          columns={columnsList}
          isAdvancedSearch={Boolean(searchType)}
          clearAll={clearBtnRef}
          setFlag_edit={setFlag_edit}
          savedSearchConfig={props.advancedSearchConfig}
          {...props}
        />
      </ConfirmPrompt>
      <ConfirmPrompt
        showModal={showPrompt}
        title="Search Changes unsaved"
        message="Your changes will be lost. Do you still want to Proceed.?"
        ariaLabeledBy="search-tab-change-dialogue."
        showCloseIcon={true}
        size={"sm"}
        setConfirm={setShowPrompt}
        confirmCallback={(val) => {
          if (val) {
            setSearchType(searchTypeToUpdate);
          }
          setShowPrompt(false);
        }}
      ></ConfirmPrompt>
    </>
  );
};

const Search = (props) => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const {
    columns = [],
    isAdvancedSearch,
    gridApi,
    setShowSearchDialog,
    clearAll,
    setFlag_edit,
    savedSearchConfig = {},
  } = props;
  const [columnsList, setColumnsList] = useState([]);
  const [formData, setFormData] = useState([]);
  const [formDependency, setFormDependency] = useState({});
  const [modelDependency, setModelDependency] = useState({});
  const [matchType, setMatchType] = useState([]);
  const [tcCode, setTcCode] = useState();
  const dispatch = useDispatch();
  const rangeInput = {
    accessor: "",
    field_type: "IntegerField",
    isClearable: false,
    isDisabled: false,
    isMulti: false,
    isSearchable: true,
    label: "",
    required: false,
  };

  /**
   * @func
   * @desc Prepare formData on every modelDependency change
   */
  useEffect(() => {
    clearSearchData();
  }, [isAdvancedSearch]);

  useEffect(() => {
    if (!formDependency || isEmpty(formDependency)) {
      setFlag_edit(false);
    } else {
      setFlag_edit(true);
    }
  }, [formDependency]);

  /**
   * @func
   * @desc Initiate Initialise all state
   */
  useEffect(() => {
    let model = gridApi.getFilterModel();
    gridApi.columnModel.columnDefs.every((col) => {
      if (col.tc_code) {
        setTcCode(col.tc_code);
        return false;
      } else {
        return true;
      }
    });
    if (
      isEmpty(model) &&
      savedSearchConfig?.search_preference &&
      isAdvancedSearch === Boolean(savedSearchConfig.tab_code)
    ) {
      model = prepareModelFromSavedConfig(savedSearchConfig.search_preference);
    }
    prepareColumnList();
    clearAll.current.onclick = clearSearchData;
    setMatchType([{ label: "All", value: "and" }]);
    setModelDependency(model);
  }, []);

  /**
   * @func
   * @desc Prepare formData on every modelDependency change
   */
  useEffect(() => {
    prepareFormObject();
  }, [modelDependency]);

  /**
   * @func
   * @desc Clear state on click of Clear filter
   */
  const clearSearchData = () => {
    setFormData([]);
    setFormDependency({});
    setModelDependency({});
  };

  /**
   * @func
   * @desc Prepare Model Object from the saved configuration
   * @param {Object} savedConfig
   * @returns {Object} newModel
   */
  const prepareModelFromSavedConfig = (savedConfig = {}) => {
    const model = {};
    savedConfig.range?.forEach((item) => {
      model[item.column] = {
        filter: item.min_val,
        filterType: "number",
        type: item.search_type,
      };
      if (item.search_type === "inRange") {
        model[item.column].filterTo = item.max_val;
      }
    });

    savedConfig.search?.forEach((item) => {
      model[item.column] = {
        filter: item.pattern,
        filterType: "text",
        type: item.search_type,
      };
    });

    return model;
  };

  /**
   * @func
   * @desc Prepare list for all the searchable columns
   */
  const prepareColumnList = () => {
    const searchableCollumns = columns.map((item) => {
      return {
        label: item.searchableLabel ? item.searchableLabel : item.label,
        value: item.accessor,
        type: item.type,
      };
    });
    setColumnsList(searchableCollumns);
  };

  /**
   * @func
   * @desc contruct form object from modelDependency and columnsList
   * @returns
   */
  const prepareFormObject = () => {
    const modelKeys = Object.keys(modelDependency);
    if (!modelKeys.length) {
      if (!formData.length && columnsList.length) {
        addEmptyFormObject();
      }
      return;
    }
    let counter = 1;
    const dependency = {};
    const newFormData = [];
    columnsList.forEach((item, index) => {
      if (modelDependency[item.value]) {
        const newRow = getEmptyRow(counter++, columnsList);
        newRow.formData[isAdvancedSearch ? 2 : 1].field_type =
          collectiveTypes[item.type].fieldType;
        dependency[newRow.accessor] = item.value;
        dependency[`${newRow.accessor}-filterVal`] =
          modelDependency[item.value].filter;
        newFormData.push(newRow);
        if (isAdvancedSearch) {
          newRow.formData[1].options = collectiveTypes[item.type].filterOptions;
          dependency[`${newRow.accessor}-filterType`] =
            modelDependency[item.value].type;
          if (modelDependency[item.value].type === "inRange") {
            newRow.formData.push(rangeInput);
            newRow.formData[3].accessor = newRow.accessor + "-filterVal2";
            dependency[`${newRow.accessor}-filterVal2`] =
              modelDependency[item.value].filterTo;
          }
        }
      }
    });
    setFormData(newFormData);
    setFormDependency(dependency);
  };

  /**
   * @func
   * @desc Prepare the template using the givem parameters
   * @param {Number} rowIndex
   * @param {Object} columnOptions
   * @returns {Object}
   */
  const getEmptyRow = (rowIndex, columnOptions) => {
    const filterTemplate = {
      accessor: `column-name-${rowIndex}-filterType`,
      field_type: "list",
      isClearable: false,
      isDisabled: false,
      isMulti: false,
      isSearchable: true,
      label: "",
      options: [],
      required: false,
    };
    const template = {
      accessor: `column-name-${rowIndex}`,
      formData: [
        {
          accessor: `column-name-${rowIndex}`,
          field_type: "list",
          isClearable: false,
          isDisabled: false,
          isMulti: false,
          isSearchable: true,
          label: "",
          options: columnOptions,
          required: false,
        },
        {
          accessor: `column-name-${rowIndex}-filterVal`,
          field_type: "TextField",
          isClearable: false,
          isDisabled: false,
          isMulti: false,
          isSearchable: true,
          label: "",
          options: [],
          required: false,
        },
      ],
    };

    if (isAdvancedSearch) {
      template.formData.splice(1, 0, filterTemplate);
    }

    return template;
  };

  /**
   * @func
   * @desc Create a new form row and add it to the existing formData
   */
  const addEmptyFormObject = () => {
    const emptyIndex =
      formData.filter((item) => item.accessor.includes("column-name")).length +
      1;
    const newRow = getEmptyRow(emptyIndex, columnsList);
    setFormData([...formData, newRow]);
  };

  /**
   * @func
   * @desc Collect user entered filter data and set the filterModel with the same
   */
  const handleApplySearch = () => {
    const newModel = getModelFromForm();
    if (Object.keys(newModel).length) {
      gridApi.setFilterModel(newModel);
    } else {
      gridApi.setFilterModel(null);
    }
    dispatch(setLastSearchType(isAdvancedSearch ? 1 : 0));
    setShowSearchDialog(false);
  };

  /**
   * @func
   * @desc Prepares Model Object from formDependency
   * @returns {Object} newModel
   */
  const getModelFromForm = () => {
    const newModel = {};
    Object.keys(formDependency).forEach((column_name) => {
      const column = columnsList.filter(
        (item) => item.value === formDependency[column_name]
      );
      if (
        column_name.includes("-filterType") ||
        column_name.includes("-filterVal") ||
        column_name.includes("-filterVal2")
      ) {
        return;
      }
      newModel[formDependency[column_name]] = {
        filterType: collectiveTypes[column[0].type].paramType,
        type: formDependency[`${column_name}-filterType`]
          ? formDependency[`${column_name}-filterType`]
          : "",
        filter: formDependency[`${column_name}-filterVal`],
      };
      if (formDependency[`${column_name}-filterType`] === "inRange") {
        newModel[formDependency[column_name]].filter =
          formDependency[`${column_name}-filterVal`];
        newModel[formDependency[column_name]].filterTo =
          formDependency[`${column_name}-filterVal2`];
      }
    });
    return newModel;
  };

  /**
   * @func
   * @desc Update form dependencies and form elements after every change
   * @param {Object} change
   * @returns
   */
  const handleChange = (change) => {
    try {
      const attributeAccessor = Object.keys(change).filter((accessor) => {
        return change[accessor] != formDependency[accessor];
      });
      if (!attributeAccessor.length) {
        return;
      } else if (!isChangeValid(change, attributeAccessor[0])) {
        displaySnackMessages("Please select an unselected column", "warning");
        const oldDependency = cloneDeep(formDependency);
        setFormDependency(oldDependency);
        return;
      }
      const updatedDependency = cloneDeep(change);
      const newFormObject = cloneDeep(formData);
      newFormObject.forEach((item) => {
        const type = isAdvancedSearch ? `${item.accessor}-filterType` : "";
        const val = `${item.accessor}-filterVal`;
        const val2 = `${item.accessor}-filterVal2`;
        switch (attributeAccessor[0]) {
          case item.accessor:
            const col = columnsList.filter(
              (listItem) => listItem.value === updatedDependency[item.accessor]
            );
            if (isAdvancedSearch) {
              delete updatedDependency[type];
              item.formData[1].options =
                collectiveTypes[col[0].type].filterOptions;
              delete updatedDependency[val2];
            }
            delete updatedDependency[val];
            item.formData[isAdvancedSearch ? 2 : 1].field_type =
              collectiveTypes[col[0].type].fieldType;
            break;
          case `${item.accessor}-filterType`:
            delete updatedDependency[val];
            delete updatedDependency[val2];
            if (updatedDependency[type] === "inRange") {
              item.formData.push(rangeInput);
              item.formData[3].accessor = val2;
            } else if (item.formData[3]) {
              item.formData.pop();
            }
            break;
          case `${item.accessor}-filterVal`:
          case `${item.accessor}-filterVal2`:
            if (
              updatedDependency[`${attributeAccessor[0]}-filterVal`] >=
                updatedDependency[`${attributeAccessor[0]}-filterVal2`] &&
              updatedDependency[type] === "inRange"
            ) {
              displaySnackMessages(
                "Please enter to and from values for range correctly",
                "warning"
              );
              return;
            }
        }
      });
      setFormData(newFormObject);
      setFormDependency(updatedDependency);
    } catch (err) {
      displaySnackMessages("Something went wrong", "error");
    }
  };

  /**
   * @func
   * @desc Validate new changes against old to know if any column values were repetative
   * @param {Object} dependency
   * @param {String} changedAccessor
   * @returns {Boolean}
   */
  const isChangeValid = (dependency, changedAccessor) => {
    if (
      ["-filterType", "-filterVal"].some((el) => changedAccessor.includes(el))
    ) {
      return true;
    }
    const count = Object.keys(dependency).filter(
      (key) => dependency[changedAccessor] === dependency[key]
    );
    return count.length > 1 ? false : true;
  };

  /**
   * @func
   * @desc Delete the formRow and formDependency with the same accessor
   * @param {String} accessor
   */
  const deleteFormObject = (accessor) => {
    let newFormData = cloneDeep(formData);
    let newDependencyArray = cloneDeep(formDependency);
    newFormData = newFormData.filter((item) => {
      if (item.accessor === accessor || accessor.includes(item.accessor)) {
        delete newDependencyArray[item.accessor];
        if (isAdvancedSearch) {
          delete newDependencyArray[`${item.accessor}-filterType`];
          if (newDependencyArray[`${item.accessor}-filterType`] === "inRange") {
            delete newDependencyArray[`${item.accessor}-filterVal2`];
          }
        }
        delete newDependencyArray[`${item.accessor}-filterVal`];
      } else {
        return item;
      }
    });
    setFormData(newFormData);
    setFormDependency(newDependencyArray);
  };

  const displaySnackMessages = (message, variance) => {
    dispatch(
      addSnack({
        message: message,
        options: {
          variant: variance,
        },
      })
    );
  };

  /**
   * @func
   * @desc Handle Save Search configuration
   */
  const handleSaveSearch = async () => {
    try {
      if (!formDependency || isEmpty(formDependency)) {
        displaySnackMessages("No Data to save.", "error");
      } else {
        let rangeConfig = [];
        let searchConfig = [];
        const newModel = getModelFromForm();
        if (!isEmpty(newModel)) {
          Object.keys(newModel).forEach((filterKey) => {
            //If the filterColumnType is number, we parse the filterBody into range field
            if (newModel[filterKey].filterType === "number") {
              rangeConfig = parseRangeBody(filterKey, newModel);
            } else {
              //Else we parse the filterBody into search field
              let patternText = newModel[filterKey].filter;
              if (newModel[filterKey].filterType === "set") {
                patternText = newModel[filterKey].values;
              }
              searchConfig.push({
                column: filterKey,
                pattern: patternText,
                search_type: newModel[filterKey].type,
              });
            }
          });
        }
        const payload = {
          tc_code: tcCode,
          tab_code: isAdvancedSearch ? 1 : 0,
          search_preference: {
            search: searchConfig,
            range: rangeConfig,
          },
        };
        await setSaveSearchConfig(payload);
        displaySnackMessages("Search saved successfully", "success");
      }
    } catch (error) {
      displaySnackMessages("Something went wrong", "error");
    }
  };

  /**
   * @func
   * @desc Render the rows and the form elements using the formData
   * @returns {Object}
   */
  const renderForm = () => {
    return (
      <>
        {formData.map((item, index) => (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item xs={11}>
              <Form
                layout={"vertical"}
                updateDefaultValue={false}
                maxFieldsInRow={item.formData.length}
                handleChange={handleChange}
                fields={item.formData}
                defaultValues={formDependency}
              ></Form>
            </Grid>
            <Grid item>
              <IconButton
                id="deleteForm"
                color="primary"
                onClick={() => deleteFormObject(item.accessor)}
                disabled={index === 0}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  return (
    <>
      {!isEmpty(columns) && (
        <>
          {isAdvancedSearch && (
            <div data-test-id="dialog-content-top-header">
              <Typography className={classes.dialogContentHeaderText}>
                <Checkbox
                  checked={true}
                  color="primary"
                  disabled={true}
                  className={classes.matchCheckbox}
                />
                Match
                <div className={classes.advanceOptionDropdown}>
                  <Grid item xs={2}>
                    <Select
                      label={matchDropdown.label}
                      is_multiple_selection={matchDropdown.isMulti}
                      filter_keyword="matchDropdown"
                      initialData={matchDropdown.options}
                      updateDependency={(dependency, selectedOption) => {
                        setMatchType([selectedOption]);
                      }}
                      selectedOptions={matchType}
                      isClearable={matchDropdown.isClearable}
                      isDisabled={true}
                    />
                  </Grid>
                </div>
                of the following rules.
              </Typography>
            </div>
          )}
          <div data-test-id="dialog-content-search-section">
            {renderForm()}
            {formData.length < columnsList.length && (
              <Button
                id="searchModal"
                variant="text"
                color="primary"
                onClick={addEmptyFormObject}
                startIcon={<AddIcon />}
                className={globalClasses.marginTop}
              >
                Add attribute
              </Button>
            )}
          </div>
        </>
      )}
      {isEmpty(columns) && (
        <div className={classes.noDataContainer}>
          <p>No Data available. Searchable columns not found.</p>
        </div>
      )}
      <div
        className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.marginTop}`}
      >
        <Button
          id="cancelSearch"
          onClick={() => setShowSearchDialog(false)}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          id="saveSearch"
          variant="outlined"
          color="primary"
          onClick={handleSaveSearch}
        >
          Save search
        </Button>
        <Button
          id="applySearch"
          variant="contained"
          onClick={handleApplySearch}
          color="primary"
        >
          Apply
        </Button>
      </div>
    </>
  );
};

export default AgGridSearch;
