import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { NavLink, withRouter } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";
import { connect } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import Drawer from "@mui/material/Drawer";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import logo from "../../assets/logo.png";
import logoNoText from "../../assets/logo_no_text.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import globalStyles from "Styles/globalStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const sidebarIcon = (itemIcon, itemTitle) => {
  if (itemIcon instanceof Object) {
    return itemIcon;
  } else {
    return <img className="icon" src={itemIcon} alt={itemTitle} />;
  }
};

const StyledListItem = withStyles((theme) => ({
  root: {
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(ListItem);

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.customVariables.navWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  "&a": {
    textDecoration: "none",
  },
  drawerOpen: {
    width: theme.customVariables.navWidth,
    overflowX: "hidden",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.customVariables.closedNavWidth,
    [theme.breakpoints.up("sm")]: {
      width: theme.customVariables.closedNavWidth,
    },
  },
  listPadding: {
    padding: "1rem 1rem",
  },
  list: {
    flex: 1,
  },
  listItemIcon: {
    color: "unset",
    minWidth: "unset",
    width: "1.435rem",

    "& .MuiSvgIcon-root": {
      fontSize: "1.375rem",
    },
  },
  listItem: {
    color: theme.palette.common.white,
    padding: 0,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  listItemText: {
    margin: "0 0 0 2rem",

    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      ...theme.typography.h5,
    },
  },
  listitemSidebar: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.white,
    cursor: "pointer",
  },
  sidebarMenu: {
    width: "100%",
    textDecoration: "none",
  },
  sidebarMiddle: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    height: theme.customVariables.closedNavWidth,
    padding: theme.spacing(2),
  },
  logoImage: {
    height: "100%",
  },
  menuIcon: {
    color: theme.palette.common.white,
    width: "1.125rem",
    height: "1.125rem",
  },
  drawerToggler: {
    background: theme.palette.primary.main,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: "50%",
    bottom: "15%",
    cursor: "pointer",
    display: "flex",
    left: (props) => {
      return props.isDrawerOpen
        ? theme.customVariables.navWidth
        : theme.customVariables.closedNavWidth;
    },
    position: "fixed",
    transform: "translateX(-50%)",
    transition: (props) => {
      return props.isDrawerOpen ? "left 225ms ease" : "left 195ms ease";
    },
    zIndex: 999,
  },
  logout: {
    cursor: "pointer",
  },
  drawerAccordion: {
    cursor: "pointer",
    backgroundColor: `${theme.palette.primary.dark}`,
    "box-shadow": "none",
    display: "grid",
    // padding: "1rem 0",
    "&.Mui-expanded": {
      margin: 0,
      padding: 0,
    },

    "&::before": {
      backgroundColor: "transparent",
      height: "0",
    },

    "& .MuiAccordionSummary-root": {
      color: `${theme.palette.common.white}`,
      backgroundColor: `${theme.palette.primary.dark}`,
      border: "0",
      "padding-left": `${theme.typography.pxToRem(16)}`,

      "&.Mui-expanded .MuiAccordionSummary-content": {
        marginBlock: 0,
      },

      "&::before": {
        backgroundColor: "transparent",
      },
    },

    "& .MuiAccordionSummary-expandIconWrapper": {
      color: `${theme.palette.common.white}`,
      padding: "0",
      marginRight: "0rem",
      width: "1.435rem",

      "& .MuiSvgIcon-root": {
        fontSize: "1.375rem",
      },
    },

    "& .MuiAccordionDetails-root": {
      padding: "0",
      color: `${theme.palette.common.white}`,
      backgroundColor: `${theme.palette.primary.dark}`,
      border: "0",
      "& a": {
        // "padding-left": "2rem",
      },
      "&::before": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const SideBar = ({ options, location, pathPrefix, logoutUser, props }) => {
  const wrapperRef = useRef();
  const [isActive, setisActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const classes = useStyles({ isDrawerOpen: isActive });
  const globalClasses = globalStyles();

  const toggleSideBarExpansionHandler = () => {
    setisActive(!isActive);
  };

  useEffect(() => {
    setSelectedIndex(findCurrentOrder(options));
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, []);

  useEffect(() => {
    if(options?.length){
      setSelectedIndex(findCurrentOrder(options));
    }
  }, [options]);

  /**
   * @func
   * @desc Find the order for current location in the provided route Options
   * @param {Object} optionList
   */
  const findCurrentOrder = (optionList) => {
    let order;
    optionList.some((option) => {
      if (option.isParent) {
        const childOrder = findCurrentOrder(option.childList);
        if (childOrder) {
          order = childOrder;
          return true;
        }
      } else if (option.link === location.pathname) {
        order = option.order;
        return true;
      }
    });
    return order;
  };

  useEffect(() => {
    props?.setUserPlatformScreen(`${location.pathname.split("/")[2]}`);
  }, [location, selectedIndex]);

  const handleClick = (e) => {
    if (wrapperRef.current.contains(e.target)) {
      return;
    }
    setisActive(false);
  };
  const handleListItemClick = async (_event, order) => {
    // props.setActiveScreenName(null);
    setSelectedIndex(order);
  };

  return (
    <Drawer
      variant="permanent"
      ref={wrapperRef}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isActive,
        [classes.drawerClose]: !isActive,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isActive,
          [classes.drawerClose]: !isActive,
        }),
      }}
    >
      <div
        className={classes.drawerToggler}
        onClick={() => toggleSideBarExpansionHandler()}
      >
        {!isActive && <ArrowForwardIcon className={classes.menuIcon} />}
        {isActive && <ArrowBackIcon className={classes.menuIcon} />}
      </div>

      <div className={classes.logo}>
        {isActive ? (
          <img
            src={logo}
            className={classes.logoImage}
            alt="IA Logo with text"
          />
        ) : (
          <img
            src={logoNoText}
            className={classes.logoImage}
            alt="IA Logo without text"
          />
        )}
      </div>
      <div className={classes.sidebarMiddle}>
        <List className={classes.list}>
          {options.map((item, i) => (
            <>
              {item?.isParent ? (
                <Accordion className={classes.drawerAccordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.listPadding}
                  >
                    <ListItemIcon
                      className={`${classes.listItemIcon} ${globalClasses.layoutAlignCenter}`}
                    >
                      {sidebarIcon(item.icon, item.title)}
                    </ListItemIcon>
                    <Tooltip title={item.title}>
                      <ListItemText
                        className={classes.listItemText}
                        primary={item.title}
                      />
                    </Tooltip>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item?.childList.map((item, i) => (
                      <Tooltip title={item.title}>
                        <StyledListItem
                          id={"sidebar-item-" + i}
                          className={classes.listItem}
                          classes={{ selected: classes.listItemSelected }}
                          button
                          key={item.title}
                          selected={selectedIndex === item.order}
                          data-screen={item.title}
                          onClick={(event) =>
                            handleListItemClick(event, item.order)
                          }
                          disabled={item.disabled}
                        >
                          <NavLink
                            to={pathPrefix + item.link}
                            className={`${classes.sidebarMenu} ${classes.listPadding}`}
                            id={item.title}
                            disabled={item.disabled}
                          >
                            <div className={classes.listitemSidebar}>
                              <ListItemIcon
                                className={`${classes.listItemIcon} ${globalClasses.layoutAlignCenter}`}
                              >
                                {sidebarIcon(item.icon, item.title)}
                              </ListItemIcon>
                              <ListItemText
                                className={classes.listItemText}
                                primary={item.title}
                              />
                            </div>
                          </NavLink>
                        </StyledListItem>
                      </Tooltip>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Tooltip title={item.title}>
                  <StyledListItem
                    id={"sidebar-item-" + i}
                    className={classes.listItem}
                    classes={{ selected: classes.listItemSelected }}
                    button
                    key={item.title}
                    selected={selectedIndex === item.order}
                    data-screen={item.title}
                    onClick={(event) => handleListItemClick(event, item.order)}
                    disabled={item.disabled}
                  >
                    <NavLink
                      to={pathPrefix + item.link}
                      className={`${classes.sidebarMenu} ${classes.listPadding}`}
                      id={item.title}
                      disabled={item.disabled}
                    >
                      <div className={classes.listitemSidebar}>
                        <ListItemIcon
                          className={`${classes.listItemIcon} ${globalClasses.layoutAlignCenter}`}
                        >
                          {sidebarIcon(item.icon, item.title)}
                        </ListItemIcon>
                        <ListItemText
                          className={classes.listItemText}
                          primary={item.title}
                        />
                      </div>
                    </NavLink>
                  </StyledListItem>
                </Tooltip>
              )}
            </>
          ))}
        </List>
      </div>
      <div className={classes.listPadding}>
        <div className={classes.listitemSidebar} onClick={() => logoutUser()}>
          <LogoutIcon
            className={`${classes.listItemIcon} ${globalClasses.layoutAlignCenter} ${classes.logout}`}
          />
          <Typography className={classes.listItemText}>Logout</Typography>
        </div>
      </div>
    </Drawer>
  );
};

SideBar.defaultProps = {
  options: [],
  pathPrefix: "",
};

SideBar.propTypes = {
  options: PropTypes.array,
  pathPrefix: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
};

export default connect(null, { logoutUser })(withRouter(SideBar));
