// Chart Icon cell renderer

import React from "react";

import BarChart from "@mui/icons-material/BarChart";
import { useStyles } from "Styles/styles-assortsmart";

const ChartCell = (props) => {
  const { onChartClick } = props;
  const classes = useStyles();
  return (
    <div
      onClick={() => {
        onChartClick(props);
      }}
    >
      <BarChart className={classes.iconBlue} />
    </div>
  );
};

export default ChartCell;
