import makeStyles from "@mui/styles/makeStyles";
import colours from "Styles/colours";

const textAlign = {
  alignItems: "center",
  margin: "1rem",
  textAlign: "center",
};

const buttonStyles = {
  marginRight: "0.5rem",
};
export const useStyles = makeStyles((theme) => ({
  smallPrimaryButton: {
    marginRight: "0.5rem",
  },
  buttonFitContent: {
    ...buttonStyles,
    width: "fit-content",
    marginLeft: "0.5rem",
    padding: "0.5rem",
    "&:first-child": {
      marginBottom: "1rem",
    },
  },
  graphCard: {
    flex: "1",
    minWidth: "500px",
    height: "40rem",
    "&.MuiCard-root": {
      overflow: "overlay",
    },
  },
  textCenter: {
    ...textAlign,
  },
  textCenterWithBackground: {
    ...textAlign,
    backgroundColor: "#41A6F7",
    color: "#fff",
  },
  createNew: {
    height: "2.4rem",
    marginLeft: "0.6rem",
    textTransform: "none",
  },
  activeBtn: {
    marginLeft: "0px !important",
    backgroundColor: "#41A6F7 !important",
    color: "#fff !important",
    width: "5rem",
    height: "2.4rem",
    borderRadius: "5%",
    border: "1px solid #41A6F7",
    cursor: "pointer",
  },
  outlinePrimary: {
    marginLeft: "0px !important",
    color: "#41A6F7",
    borderColor: "#41A6F7",
    width: "5rem",
    height: "2.4rem",
    borderRadius: "5%",
    border: "1px solid #41A6F7",
    cursor: "pointer",
  },
  btnGroup: {
    position: "relative",
    display: "inline-flex",
    cursor: "pointer",
  },
  addIcon: {
    backgroundColor: "#41a6f7",
    color: "white",
    "&:hover": {
      backgroundColor: "#41a6f7",
    },
  },
  buttonDiv: {
    textAlign: "center",
    marginTop: 20,
  },
  scaleUpDownBtn: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  hyperLink: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "darkblue",
    textDecoration: "underline",
  },
  iconBlue: {
    fontSize: theme.typography.pxToRem(25),
    color: theme.palette.primary.main,
    marginTop: "-5px",
    cursor: "pointer",
  },

  secondaryHeading: {
    fontSize: "20px",
    fontWeight: 600,
  },
  heading: {
    alignItems: "center",
    display: "flex",
    marginBottom: "1.5rem",
    whiteSpace: "nowrap",
  },
  dFlex: {
    alignItems: "center",
    display: "flex",
    margin: "0.5rem 0",
  },
  TextField: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100 %",
    },
    "& .MuiInputBase-input": {
      padding: "10px",
    },
    marginBottom: "1rem",
  },
  legend: {
    fontSize: "0.8rem",
    color: "#727c87",
  },
  smallButtonDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  // Dialog box styles
  content: {
    padding: "0",
  },
  dialog: {
    "& .MuiDialog-paperWidthLg": {
      overflowY: "visible",
      position: "absolute",
    },
  },
  contentBody: {
    overflowY: "visible",
  },
  // store swap table styles
  resultContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableLayout: {
    width: "45%",
  },
  formWidth: {
    width: "60%",
    marginBottom: "15px",
  },
  typographyMarginBottom: {
    marginBottom: "20px",
  },
  receiptDrawerButton: {
    marginTop: "60px",
    minWidth: "45px",
    maxWidth: "45px",
    boxShadow: "0 1px 15px 1px rgba(62, 57, 107, 0.17)",
    height: "50px",
    position: "fixed",
    zIndex: "500",
    right: 0,
    borderRadius: "50%",
    transform: "translateY(-50%)",
    textAlign: "center",
    paddingTop: "10px",
    marginRight: "50px",
  },
  bopButton: {
    marginTop: "175px",
    marginRight: "50px",
  },
  benchmarkButton: {
    marginTop: "240px",
    marginRight: "50px",
  },
  nonLinearButton: {
    marginTop: "120px",
    marginRight: "50px",
  },
  receiptDrawerButtonEnabled: {
    background: theme.palette.primary.main,
    cursor: "pointer",
    color: "#fff",
  },
  receiptDrawerButtonDisabled: {
    color: "#ffffff !important",
    background: "grey !important",
    pointerEvents: "none",
  },
  dialogGrid: { width: "100%", padding: "0 10px", marginBottom: "1rem" },
  planFinalizeHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  planFinalizeFormContainer: {
    width: "50%",
    marginBottom: "10px",
  },
  secondaryLabel: {
    color: "#9299A2",
    marginBottom: "0.3rem",
  },
  actionIcon: {
    padding: "0px",
  },
  assortSingleFilterView: {
    paddingLeft: "32px",
  },
  assortMultiFilterView: {
    paddingLeft: "32px",
  },
  rightAlignButtonAssort: {
    textAlign: "right",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  depthMultiplierDivContainer: {
    width: "75%",
    margin: "0 auto",
  },
  inputGroup: {
    marginBottom: theme.spacing(1),
  },
  emptyDiv: {
    marginRight: "15rem",
  },
  filtersCont: {
    display: "flex",
  },
  dashboardFilterContainer: {
    display: "flex",
  },
  dashboardFiltersBtnsDiv: {
    display: "flex",
    alignSelf: "end",
    marginLeft: "15px",
    marginTop: "20px",
    justifyContent: "flex-end"
  },
  filterContainer: {
    marginBottom: "20px",
  },
  depthChoiceFlex: {
    display: "flex",
    gap: "2rem",
  },
  clusterNotes: {
    color: theme.palette.primary.main,
  },
  cardGrid: {
    width: "130%",
  },
  headerDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
  },
  paperStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  createBtn: {
    padding: theme.typography.pxToRem(10),
    borderRadius: "2%",
    minWidth: "10%",
  },
  createLabel: {
    marginTop: 5,
    fontSize: 20,
  },
  centerBtn: {
    justifyContent: "center !important",
  },
  marginBottom15: {
    marginBottom: "15px",
  },
  marginTop10: {
    marginTop: "20px",
  },
  bottomButtonDiv: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  bottomButton: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  button: {
    marginRight: 5,
  },
  omniMappingFilterDiv: {
    justifyContent: "space-between",
    display: "flex",
    margin: "1rem",
  },
  omniMappingFilter: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 1rem",
    whiteSpace: "break-spaces",
  },
  omniMappingFilterRow: {
    display: "flex",
    flexDirection: "row",
  },
  choiceHeading: {
    display: "flex",
    justifyContent: "space-between",
    margin: "30px 0px",
  },
  choiceBtnAlignRight: {
    float: "right",
  },
  choiceActionDiv: {
    marginBottom: "70px",
    marginTop: "15px",
  },
  choiceLabel: {
    fontWeight: "600",
    fontSize: "1.05rem",
  },
  choiceTable: {
    zIndex: "1",
    position: "relative",
  },
  switchContainer: {
    display: "flex",
    alignItems: "center",
    "& > span": {
      marginRight: theme.typography.pxToRem(5),
    },
  },
  ORTextGrid: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
  },
  searchGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  ORText: {
    color: theme.palette.colours.filterLabelColor,
  },
  rightEnd: {
    marginLeft: "auto",
    display: "flex",
  },
  buttonFitMargin: {
    ...buttonStyles,
    width: "fit-content",
    marginLeft: "0.5rem",
    marginBottom: "1rem",
    padding: "0.5rem",
  },
  NLEFooter: {
    justifyContent: "space-between",
    margin: "0 20px",
  },
  deletePlanDiv: {
    width: "100%",
  },
  buyUnitsDivBackground: {
    backgroundColor: colours.navajoWhite,
  },
  formContainer: {
    width: "25%",
    marginLeft: "20px",
    marginBottom: "20px",
    "& .MuiInputLabel-root": {
      fontWeight: 700,
    },
  },
  closeIcon: {
    position: "absolute",
    right: 10,
    top: 8,
  },
  scaleBtn:{
    display: "flex",
    justifyContent: "end",
    margin: "inherit"
  },
  depthTableWidth: {
    margin: `0 ${theme.typography.pxToRem(50)}`,
  },
}));
