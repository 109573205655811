import React from "react";
import withStyles from "@mui/styles/withStyles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    background: theme.palette.background.primary,
    marginBottom: theme.spacing(2),
    position: "sticky",
    top: theme.customVariables.closedNavWidth,
    zIndex: 9,
  },
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    color: theme.palette.textColours.codGray,
    fontWeight: theme.typography.fontWeightRegular,
    padding: 0,
  },
  label: {
    padding: "0 0.5rem",
    ...theme.typography.body1,
  },
  clickable: {
    color: theme.palette.textColours.slateGrayLight,
    cursor: "pointer",

    "&:hover, &:focus, &:active": {
      backgroundColor: "transparent",
      boxShadow: "none",
      color: theme.palette.textColours.slateGrayLight,
    },
  },
}))(Chip);

function handleClick(event, action) {
  event.preventDefault();
  action();
}

export default function HeaderBreadCrumbs({ options }) {
  const classNames = useStyles();
  return (
    <Container maxWidth={false} className={classNames.breadcrumbContainer}>
      <Breadcrumbs aria-label="breadcrumb">
        {options.map((option, i) => {
          let clickable = options.length - 1 !== i;
          return (
            <StyledBreadcrumb
              key={i}
              id={option.id}
              label={option.label}
              icon={option.icon}
              onClick={(event) => {
                if (clickable) {
                  handleClick(event, option.action);
                }
              }}
              disableRipple={true}
              clickable={clickable}
            />
          );
        })}
      </Breadcrumbs>
    </Container>
  );
}
