export const USER_ROLE_FILTERS = [
  {
    column_name: "application_name",
    default_value: null,
    dimension: "user",
    display_type: "dropdown",
    filter_keyword: "application_name",
    initialData: [],
    is_mandatory: false,
    is_multiple_selection: true,
    label: "User Application",
    level: 1,
    range_max: null,
    range_min: null,
    type: "non-cascaded",
    is_clearable: true,
  },
  {
    column_name: "role_name",
    default_value: null,
    dimension: "user",
    display_type: "dropdown",
    filter_keyword: "role_name",
    initialData: [],
    is_mandatory: false,
    is_multiple_selection: true,
    label: "User Role",
    level: 2,
    range_max: null,
    range_min: null,
    type: "non-cascaded",
    is_disabled: true,
    is_clearable: true,
  },
];

export const USER_FORM = [
  {
    // state var name accessor
    accessor: "user_email",
    field_type: "list",
    label: "User Email Id",
    required: true,
    isMulti: true,
    isSearchable: true,
    options: [],
  },
];

export const USER_EMAIL_FILTER = [
  {
    column_name: "user_email",
    default_value: null,
    dimension: "user",
    display_type: "dropdown",
    filter_keyword: "user_email",
    initialData: [],
    is_mandatory: false,
    is_multiple_selection: true,
    label: "User Email",
    level: 1,
    range_max: null,
    range_min: null,
    type: "cascaded",
    is_clearable: true,
    display_order: 1,
  },
];

export const USER_NAME_FORM = [
  {
    accessor: "user_name",
    column_name: "user_name",
    default_value: null,
    dimension: "product",
    display_order: 1,
    display_type: "TextField",
    error: false,
    extra: { helperText: "Enter a valid user name" },
    field_type: "TextField",
    helperText: "Enter a valid user name",
    is_clearable: false,
    is_disabled: false,
    is_mandatory: true,
    is_multiple_selection: false,
    is_required: true,
    label: "Edit User Name",
    level: null,
    range_max: null,
    range_min: null,
    required: true,
    type: "non-cascaded",
    value_type: "text",
  },
];

export const ASSIGN_ROLE_USER_DETAILS_FILTERS = [
  {
    column_name: "application_name",
    default_value: null,
    dimension: "user",
    display_type: "dropdown",
    filter_keyword: "application_name",
    initialData: [],
    is_mandatory: true,
    is_multiple_selection: false,
    label: "Assign Application",
    level: 2,
    range_max: null,
    range_min: null,
    type: "cascaded",
    is_clearable: true,
    display_order: 2,
  },
  {
    column_name: "role_name",
    default_value: null,
    dimension: "user",
    display_type: "dropdown",
    filter_keyword: "role_name",
    initialData: [],
    is_mandatory: true,
    is_multiple_selection: false,
    label: "Assign Role",
    level: 3,
    range_max: null,
    range_min: null,
    type: "cascaded",
    is_clearable: true,
    display_order: 3,
  },
];

// add list of super user's based on tenant
export const SUPER_USERS = [
  "superuser",
  "admin",
  "Super user",
  "Administrator",
  "Platform-admin",
  "Platform-edit",
  "Platform-view"
];
