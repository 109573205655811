import React, { Suspense, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import SideBar from "../sidebar/SideBar";
import Header from "../header";
import { withRouter, Switch } from "react-router-dom";
import { APP_PLATFORM } from "config/constants";
import NotFound from "../notFound/NotFound";
import store from "../../store";
import LoadingOverlay from "Utils/Loader/loader";
import layoutActions from "actions/layoutActions";
import {
  sideBarActions,
  sideBarData,
  sideBarDataDispatch,
  setUserPlatformScreen,
  setActiveUserApp,
  activeSideBarData,
} from "actions/sideBarActions";
import "./layout.css";
import ProtectedRoute from "./ProtectedRoute";
import Skeleton from "@mui/material/Skeleton";
import {
  getAccessHierarchy,
  hasSuperUserAccess,
} from "Utils/filter-accessible-data";
import {
  setIsSuperUser,
  setPlanningLevelHierarchy,
  setUserLevelHierarchy,
  setUserAccessList,
  setTenantTimeconfig,
  setfilterAttributeExclusionValues,
  setAllAPIsIncludedInFilterExclusion,
  getFilterConfiguration,
} from "pages/tenant-config/access-user-management/services/TenantManagement/User-Role-Management/user-role-management-service";
import {
  getPlanningHierarchy,
  setSavedFilterData,
  setSavedFilterSelectionLoading,
  getSavedFilterSelection,
} from "actions/filterAction";
import CommentBar from "commonComponents/commentbar/CommentBar";
// import { setActiveScreenName } from "modules/assortsmart/services-assortsmart/common-assort-service";
import { useHistory } from "react-router";
import { sideBarDataSet } from "config/sidebar";
import {
  getTenantTimeConfig,
  getFilterExclusionValues,
} from "actions/tenantConfigActions";
import { appName } from "config/constants";
import { addSnack } from "actions/snackbarActions";

const Layout = (props) => {
  const history = useHistory();
  const { path } = props.match;
  const dispatch = useDispatch();
  const [screens, setScreens] = useState();
  const [routes, setRoutes] = useState();
  const [requiredApisCalled, setRequiredApisCalled] = useState(false);

  /**
   * @func
   * @desc
   * Update component state routes and screens based on store data and props,
   * The dependent store and props to watch are routes, screens, userScreenData.
   * This sideBarDataDispatch sets the userScreenData.
   * It updates from the state again with the latest routes if userScreenData changes.
   */

  useEffect(() => {
    const setSideBarOption = async () => {
      try {
        let screenArray;
        let activeSideBarData = store.getState()?.sideBarReducer
          ?.activeSideBarData;
        let activeAppName = props.activeAppName
          ? props.activeAppName
          : sessionStorage.getItem("currentApp");
        let currentSideBarData =
          activeSideBarData.length > 0
            ? activeSideBarData
            : sideBarDataSet[activeAppName];

        let updatedRoute = [...props.routes];
        let appName = props.app || getAppName();
        let updatedScreens = [];

        if (
          activeAppName !== appName &&
          activeAppName &&
          window.location.pathname.includes("/product-grouping")
        ) {
          if (!activeSideBarData.length > 0) {
            let existingSideBar = JSON.parse(
              sessionStorage.getItem("currentSideBar")
            );
            let existingSideBarModule = existingSideBar.map(
              (item) => item.module
            );
            currentSideBarData = currentSideBarData
              .filter((item) => existingSideBarModule.indexOf(item.module) > -1)
              .map((item) => {
                let sideBarItemData = existingSideBar.filter(
                  (e) => e.module === item.module
                )[0];
                item.title = sideBarItemData.title;
                return item;
              });
          }

          updatedScreens = currentSideBarData;
          appName = activeAppName;
        } else {
          updatedScreens = props.sideBarOptions
            ? props.sideBarOptions
            : currentSideBarData;
        }

        if (props.userScreenData[appName]?.screens) {
          screenArray = props.userScreenData[appName]?.screens;
        } else {
          // const screenData = await sideBarData(appName);
          const screenData = {}
          /**
           * Structure of the api has changed to make screen accessible based on roles and screns assigned to a user
           * Default Value is "All", in case a user hasn't been given an access to a particular app, it comes as blank object and Home page fails to load
           */
          screenData?.data?.data?.data?.length > 0
            ? screenData?.data?.data?.data?.forEach((e) => {
                screenArray = e.users.screen.map((item) => item.name);
              })
            : (screenArray = ["All"]);

          /** Changing userScreenData structure as comparison for active and accessible apps are changing in Homepage */
          dispatch(
            sideBarDataDispatch({
              [appName]: { screens: screenArray, appName },
            })
          );
        }
        if (!screenArray.includes("All")) {
          const accessibleRouteOptions = props.routes?.filter((e) => {
            if (Array.isArray(e.screenName)) {
              const found = screenArray.some((r) => e.screenName.includes(r));
              return found;
            } else {
              return screenArray.includes(e.screenName);
            }
          });
          const activeOptions = activeSideBarOptions(
            screenArray,
            updatedScreens
          );
          updatedRoute =
            accessibleRouteOptions.length > 0
              ? accessibleRouteOptions
              : updatedRoute;
          updatedScreens = activeOptions ? activeOptions : updatedScreens;
        }

        if (window.location.pathname !== "/home") {
          sessionStorage.setItem(
            "currentSideBar",
            JSON.stringify(updatedScreens)
          );
        }
        sessionStorage.setItem("currentApp", appName);
        props.setActiveUserApp(appName);
        props.setActiveSideBarData(updatedScreens);
        setScreens(updatedScreens);
        setRoutes(updatedRoute);
      } catch (error) {
        console.log(error);
      }
    };
    setSideBarOption();
  }, [props.routes, props.sideBarOptions, props.userScreenData]);

  useEffect(() => {
    const setConfiguration = async () => {
      try {
        // let planningHierarchyResponse = await props.getPlanningHierarchy(2, {
        //   attribute_name: "planning_level_hierarchy",
        // });
        props.setPlanningLevelHierarchy(
          []
        );
        // let filterConfiguration = await getFilterConfiguration()();
        // filterConfiguration = filterConfiguration?.data?.data.map((filter) => {
        //   return filter.column_name;
        // });
        props.setUserLevelHierarchy([]);
        // const savedFilterSelection = await getSavedFilterSelection().catch(
        //   () => {
        //     props.setSavedFilterSelectionLoading();
        //   }
        // );
        props.setSavedFilterData([]);

        // const accessData = await getAccessHierarchy();
        // console.log(accessData)
        // props.setUserAccessList(accessData);
        // props.setIsSuperUser(hasSuperUserAccess(accessData));
        // const tenantTimeconfig = await getTenantTimeConfig();
        // props.setTenantTimeconfig(tenantTimeconfig);
        // added excluded filter values as dependency in redux
        // const excludedValues = await getFilterExclusionValues();
        const excludedValues = {}
        const l_currentApp = sessionStorage
          .getItem("currentApp")
          ?.toLowerCase()
          ?.toLowerCase();
        const l_currentAppFromPath = history.location.pathname;
        if (
          l_currentApp === "inventorysmart" ||
          l_currentAppFromPath.includes("inventory-smart")
        ) {
          props.setAllAPIsIncludedInFilterExclusion(false
          );
        }
        if (excludedValues[0]?.attribute_value?.value) {
          let filterData = excludedValues[0]?.attribute_value?.value;
          let finalDependency = [];
          Object.keys(filterData).forEach((key) => {
            let filtersData = Object.keys(filterData[key]).map((item) => {
              return {
                attribute_name: item,
                operator: "not in",
                values: filterData[key][item],
                filter_type: "cascaded",
                dimension: key,
              };
            });
            finalDependency = [...finalDependency, ...filtersData];
          });
          props.setfilterAttributeExclusionValues([]);
        }
        setRequiredApisCalled(true);
      } catch (error) {
        console.log(error);
        props.addSnack({
          message: "Something went wrong",
          options: {
            variant: "error",
          },
        });
      }
    };
    setConfiguration();
  }, []);

  /**
   * @func
   * @desc Check if the route matches the workflow input center and set session to workflow
   * @returns return the current app as 'workflow input center'
   */
  const getAppName = () => {
    let isWorkFlowCenter = false;
    props.routes.forEach((item) => {
      if (item.path === window.location.pathname) {
        isWorkFlowCenter = true;
        sessionStorage.setItem("currentApp", appName.WORKFLOW_INPUT_CENTER);
        return;
      }
    });
    return isWorkFlowCenter ? appName.WORKFLOW_INPUT_CENTER : "";
  };

  /**
   * @func
   * @desc Filter Sidebar Options based on the screenArray Provided
   * @param {Object} screenArray
   * @returns {Object} active sidebar Options
   */
  const activeSideBarOptions = (screenArray, sideBarOptions) => {
    const accessibleSideBarOptions = sideBarOptions
      ? sideBarOptions
      : [...props.sideBarOptions];
    const screenNameOption = [];
    accessibleSideBarOptions?.forEach((e) => {
      if (e.screenName && screenArray.includes(e.screenName)) {
        screenNameOption.push(e);
      } else if (e.isParent) {
        e.childList?.forEach((r) => {
          r.disabled = !screenArray.includes(r.title.replace("SKU", "Product"));
        });
      } else {
        e.disabled =
          !screenArray.includes(e.title.replace("SKU", "Product")) &&
          e.title !== "Sourcing";
      }
    });
    return screenNameOption.length
      ? screenNameOption
      : accessibleSideBarOptions;
  };

  return (
    <LoadingOverlay
      loader={props.overlayLoaderState || props.loading || !requiredApisCalled}
      spinner
    >
      {routes && (
        <div className="wrapper">
          <SideBar
            options={screens}
            props={props}
            setActivePlanStep={props.setActivePlanStep}
          />
          <div className="layout">
            <Header title={APP_PLATFORM.APP_NAME} />
            <div className="main-content" id="content">
              <div id="sub-content">
                <Suspense
                  fallback={
                    <div>
                      <Skeleton> </Skeleton>
                    </div>
                  }
                >
                  {requiredApisCalled && (
                    <Switch>
                      {routes.map((route) => (
                        <ProtectedRoute
                          exact
                          path={`${route.path}`}
                          component={route.component}
                          screenName={route.screenName}
                          module={route.module}
                        />
                      ))}
                      <ProtectedRoute
                        exact
                        path={`${path}/*`}
                        component={NotFound}
                      />
                    </Switch>
                  )}
                </Suspense>
              </div>
            </div>
          </div>
          <CommentBar
            showCommentScreenNameOption={props.showCommentScreenNameOption}
            commentBarPlaceholder={props.commentBarPlaceholder}
          />
        </div>
      )}
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => ({
  overlayLoaderState: state.loaderReducer.overlayLoaderState,
  userScreenData: state.sideBarReducer.userScreenData,
  activeAppName: state.sideBarReducer.activeAppName,
});

const mapActionsToProps = {
  setUserScreen: sideBarActions,
  setActiveSideBarData: activeSideBarData,
  setLayout: layoutActions.setLayout,
  setIsSuperUser,
  getPlanningHierarchy,
  setPlanningLevelHierarchy,
  setUserLevelHierarchy,
  setUserAccessList,
  setUserPlatformScreen,
  // setActiveScreenName,
  setTenantTimeconfig,
  setfilterAttributeExclusionValues,
  setAllAPIsIncludedInFilterExclusion,
  setSavedFilterData,
  setSavedFilterSelectionLoading,
  setActiveUserApp,
  addSnack,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Layout));
