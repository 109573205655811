import React from "react";
import ConfirmPrompt from "commonComponents/confirmPrompt";

const CreateTicket = (props) => {
  return (
    <ConfirmPrompt
      showModal={true}
      message={
        props.text
          ? props.text
          : "All input data will be lost. Do you really want to leave without saving"
      }
      title={props.title ? props.title : "Unsaved Changes"}
      ariaLabeledBy="customized-dialog-title"
      primaryBtnText="Confirm"
      secondaryBtnText="< Go Back"
      setConfirm={() => props.onClose()}
      confirmCallback={(val) => {
        if (val) {
          props.onConfirm();
        }
      }}
    />
  );
};

export default CreateTicket;
