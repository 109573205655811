const mainReducerDefaultState = {
    overlayLoaderState: false,
    loaderText: 'Loading...',
    isLoading: false,
    isSchemaLoading: false,
  };
  
  export default (state = mainReducerDefaultState, action) => {
    switch (action.type) {
      case 'LOADER':
        return {
          ...state,
          overlayLoaderState: action.payload.status,
          loaderText: action.payload.text,
        };
      case 'PRODUCTIVITY_LOADER':
        return {
          ...state,
          isLoading: action.payload.status,
          loaderText: action.payload.text,
        };
      case 'PRODUCTIVITY_SCHEMA_LOADER':
        return {
          ...state,
          isSchemaLoading: action.payload.status,
          loaderText: action.payload.text,
        };
      default:
        return state;
    }
  };