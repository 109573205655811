import { BASE_API, BASE_API_TEST, BASE_API_V3, REDIRECT_URL } from "./api";

export const config = {
  baseUrl: BASE_API,
  baseUrlTest: BASE_API_TEST,
  baseUrlV3: BASE_API_V3,
  redirectURL: REDIRECT_URL,
};

export const calendarConfig = {
  fstDayOfWk: 0, // Monday
  fstMoOfYr: 0, // Jan
};

export const apmRumConfig = {
  configHost: "https://assort-apm.impactsmartsuite.com/apm/",
  configName: "DEV-CORE-FE",
};
