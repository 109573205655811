import axiosInstance from "Utils/axios";
import { SET_USER_MANAGEMENT_LIST } from "./types";
import { GET_SCREEN_LEVEL_ACCESS } from "config/api";

export const getHierarchyAccessDetails = (app, screen) => {
  return axiosInstance({
    url: `${GET_SCREEN_LEVEL_ACCESS}?app=${app}&screen=${screen}`,
    method: "GET",
  });
};

export const setUserManagementList = (data) => async (dispatch) => {
  dispatch({
    type: SET_USER_MANAGEMENT_LIST,
    payload: data,
  });
};
