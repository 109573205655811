export const newConnectorFormFields = [
    {
      fc_code: 281,
      label: "Type",
      column_name: "type",
      type: "non-cascaded",
      display_type: "dropdown",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 2,
      is_required: true,
      options: [
        { label: "GCS", value: "gcs" },
        { label: "MSSQL", value: "mssql" },
        { label: "SFTP", value: "sftp" },
        { label: "Snowflake", value: "snowflake" },
      ],
      extra: {
        helperText: "Select a valid type",
      },
    },
    {
      fc_code: 280,
      label: "Display name",
      column_name: "name",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 1,
      is_required: false,
      prefix: true,
      extra: {
        helperText: "Enter a valid name",
      },
    },
  ]

export const gcsConnectorFormFields = [
    {
      fc_code: 282,
      label: "Bucket name",
      column_name: "bucket",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 3,
      is_required: true,
      extra: {
        helperText: "Enter a valid bucket name",
      },
    },
    {
      fc_code: 282,
      label: "root directory",
      column_name: "blob_prefix",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 3,
      is_required: false,
      extra: {
        helperText: "Enter a root directory",
      },
    },
    {
      fc_code: 282,
      label: "File Pattern",
      column_name: "file_prefix",
      type: "non-cascaded",
      display_type: "dropdown",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: "yyyyMMdd/{view}/",
      is_disabled: false,
      is_clearable: false,
      display_order: 3,
      is_required: false,
      options: [
        { label: "yyyyMMdd/{view}/", value: "yyyyMMdd/{view}/" },
        { label: "yyyy-MM-dd/{view}_\\d+.", value: "yyyy-MM-dd/{view}_\\d+." },
        { label: "yyyy-MM-dd/{view}/", value: "yyyy-MM-dd/{view}/" },
        { label: "yyyyMMdd/{view}_\\d+.", value: "yyyyMMdd/{view}_\\d+." },
      ],
      extra: {
        helperText: "Select file pattern",
      },
    },
    {
      fc_code: 282,
      label: "File Extension",
      column_name: "file_extension",
      type: "non-cascaded",
      display_type: "dropdown",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: true,
      isMulti:true,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 3,
      is_required: true,
      options: [
        { label: "csv", value: "csv"},
        { label: "dat", value: "dat" },
        { label: "txt", value: "txt" },
        { label: "csv.gz", value: "csv.gz" },
        {label: "dat.gz", value: "dat.gz"},
        {label: "txt.gz", value: "txt.gz"},
        {label: "parquet", value: "parquet"},
      ],
      extra: {
        helperText: "Select file pattern",
      },
    },
  ]

export const mysqlConnectorFormFields = [
    {
      fc_code: 281,
      label: "host",
      column_name: "host",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 2,
      is_required: true,
      extra: {
        helperText: "Enter a valid host name",
      },
    },
    {
      fc_code: 282,
      label: "User",
      column_name: "user",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 3,
      is_required: true,
      extra: {
        helperText: "Enter a valid user name",
      },
    },
    {
      fc_code: 280,
      label: "Password",
      column_name: "password",
      type: "password",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 1,
      is_required: true,
      extra: {
        helperText: "Enter a password",
      },
    },
    {
        fc_code: 282,
        label: "Database",
        column_name: "database",
        type: "non-cascaded",
        display_type: "TextField",
        level: null,
        dimension: "product",
        is_mandatory: true,
        is_multiple_selection: false,
        range_min: null,
        range_max: null,
        default_value: null,
        is_disabled: false,
        is_clearable: false,
        display_order: 3,
        is_required: true,
        extra: {
          helperText: "Enter a valid database name",
        },
      },
      {
        fc_code: 282,
        label: "Schema",
        column_name: "schema",
        type: "non-cascaded",
        display_type: "TextField",
        level: null,
        dimension: "product",
        is_mandatory: false,
        is_multiple_selection: false,
        range_min: null,
        range_max: null,
        default_value: null,
        is_disabled: false,
        is_clearable: false,
        display_order: 3,
        is_required: false,
        extra: {
          helperText: "Enter a valid schema name",
        },
      }, 
  ] 

export const sftpConnectorFormFields = [
    {
      fc_code: 281,
      label: "host",
      column_name: "host",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 2,
      is_required: true,
      extra: {
        helperText: "Enter a valid host name",
      },
    },
    {
      fc_code: 282,
      label: "User",
      column_name: "user",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 3,
      is_required: true,
      extra: {
        helperText: "Enter a valid user name",
      },
    },
    {
      fc_code: 280,
      label: "Password",
      column_name: "password",
      type: "password",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 1,
      is_required: true,
      extra: {
        helperText: "Enter a password",
      },
    },
    {
        fc_code: 281,
        label: "port",
        column_name: "port",
        type: "non-cascaded",
        display_type: "IntegerField",
        level: null,
        dimension: "product",
        is_mandatory: true,
        is_multiple_selection: false,
        range_min: null,
        range_max: null,
        default_value: 22,
        is_disabled: false,
        is_clearable: false,
        display_order: 2,
        is_required: true,
        extra: {
          helperText: "Enter a valid port name",
        },
      },
      {
        fc_code: 282,
        label: "root directory",
        column_name: "path",
        type: "non-cascaded",
        display_type: "TextField",
        level: null,
        dimension: "product",
        is_mandatory: false,
        is_multiple_selection: false,
        range_min: null,
        range_max: null,
        default_value: null,
        is_disabled: false,
        is_clearable: false,
        display_order: 3,
        is_required: false,
        extra: {
          helperText: "Enter a root directory",
        },
      },
      {
        fc_code: 282,
        label: "File Pattern",
        column_name: "file_prefix",
        type: "non-cascaded",
        display_type: "dropdown",
        level: null,
        dimension: "product",
        is_mandatory: false,
        is_multiple_selection: false,
        range_min: null,
        range_max: null,
        default_value: "yyyyMMdd/{view}/",
        is_disabled: false,
        is_clearable: false,
        display_order: 3,
        is_required: false,
        options: [
          { label: "yyyyMMdd/{view}/", value: "yyyyMMdd/{view}/" },
          { label: "yyyy-MM-dd/{view}_\\d+.", value: "yyyy-MM-dd/{view}_\\d+." },
          { label: "yyyy-MM-dd/{view}/", value: "yyyy-MM-dd/{view}/" },
          { label: "yyyyMMdd/{view}_\\d+.", value: "yyyyMMdd/{view}_\\d+." },
        ],
        extra: {
          helperText: "Select file pattern",
        },
      },
      {
        fc_code: 282,
        label: "File Extension",
        column_name: "file_extension",
        type: "non-cascaded",
        display_type: "dropdown",
        level: null,
        dimension: "product",
        is_mandatory: true,
        is_multiple_selection: true,
        isMulti:true,
        range_min: null,
        range_max: null,
        default_value: null,
        is_disabled: false,
        is_clearable: false,
        display_order: 3,
        is_required: true,
        options: [
          { label: "csv", value: "csv"},
          { label: "dat", value: "dat" },
          { label: "txt", value: "txt" },
          { label: "csv.gz", value: "csv.gz" },
          {label: "dat.gz", value: "dat.gz"},
          {label: "txt.gz", value: "txt.gz"},
          {label: "parquet", value: "parquet"},
        ],
        extra: {
          helperText: "Select file pattern",
        },
      },
  ] 

export const snowflakeConnectorFormFields = [
    {
      fc_code: 281,
      label: "Account",
      column_name: "account",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 2,
      is_required: true,
      extra: {
        helperText: "Enter a valid account",
      },
    },
    {
      fc_code: 282,
      label: "Warehouse",
      column_name: "warehouse",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 3,
      is_required: true,
      extra: {
        helperText: "Enter a valid warehouse",
      },
    },
    {
        fc_code: 282,
        label: "Database",
        column_name: "database",
        type: "non-cascaded",
        display_type: "TextField",
        level: null,
        dimension: "product",
        is_mandatory: true,
        is_multiple_selection: false,
        range_min: null,
        range_max: null,
        default_value: null,
        is_disabled: false,
        is_clearable: false,
        display_order: 3,
        is_required: true,
        extra: {
          helperText: "Enter a valid database name",
        },
      },
      {
        fc_code: 282,
        label: "Schema",
        column_name: "schema",
        type: "non-cascaded",
        display_type: "TextField",
        level: null,
        dimension: "product",
        is_mandatory: true,
        is_multiple_selection: false,
        range_min: null,
        range_max: null,
        default_value: null,
        is_disabled: false,
        is_clearable: false,
        display_order: 3,
        is_required: true,
        extra: {
          helperText: "Enter a valid schema name",
        },
      },
      {
        fc_code: 282,
        label: "User",
        column_name: "user",
        type: "non-cascaded",
        display_type: "TextField",
        level: null,
        dimension: "product",
        is_mandatory: true,
        is_multiple_selection: false,
        range_min: null,
        range_max: null,
        default_value: null,
        is_disabled: false,
        is_clearable: false,
        display_order: 3,
        is_required: true,
        extra: {
          helperText: "Enter a valid username",
        },
      },
    {
      fc_code: 280,
      label: "Password",
      column_name: "password",
      type: "password",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 1,
      is_required: true,
      extra: {
        helperText: "Enter a password",
      },
    },
    {
      label: "Role",
      column_name: "role",
      display_type: "dropdown",
      is_mandatory: true,
      is_required: true,
      options: [
        { label: "ACCOUNTADMIN", value: "ACCOUNTADMIN"},
        { label: "SECURITYADMIN", value: "SECURITYADMIN" },
        { label: "USERADMIN", value: "USERADMIN" },
        { label: "SYSADMIN", value: "SYSADMIN" },
        {label: "PUBLIC", value: "PUBLIC"},
      ],
    },
    {
      label: "Storage Integration", 
      column_name: "storage_integration",
      display_type: "TextField",
      is_mandatory: true,
      is_required: true
    },
  ] 