export const sampleViewFields = {
  columns: [
    {
      sub_headers: [],
      tc_code: 1,
      label: "column Name",
      column_name: "column_name",
      dimension: "Product",
      type: "str",
      is_frozen: false,
      is_editable: false,
      is_aggregated: false,
      order_of_display: 1,
      is_hidden: false,
      is_required: false,
      tc_mapping_code: 15,
      aggregate_type: "",
      formatter: "",
      is_row_span: false,
      footer: "",
      is_searchable: false,
      extra: {},
      sortable: true,
      width: 200,
    },
    {
      sub_headers: [],
      tc_code: 2,
      label: "Data type",
      column_name: "data_type",
      dimension: "Product",
      type: "str",
      is_frozen: false,
      is_editable: false,
      is_aggregated: false,
      order_of_display: 1,
      is_hidden: false,
      is_required: false,
      tc_mapping_code: 15,
      aggregate_type: "",
      formatter: "",
      is_row_span: false,
      footer: "",
      is_searchable: false,
      isSearchable: true,
      extra: {},
      isMulti: false,
      sortable: false,
      width: 200,
      minWidth: 200,
    },
  ],
  indexes: [
    {
      sub_headers: [],
      tc_code: 1,
      label: "Index Name",
      column_name: "index_name",
      dimension: "Product",
      type: "str",
      is_frozen: false,
      is_editable: false,
      is_aggregated: false,
      order_of_display: 1,
      is_hidden: false,
      is_required: false,
      tc_mapping_code: 15,
      aggregate_type: "",
      formatter: "",
      is_row_span: false,
      footer: "",
      is_searchable: false,
      extra: {},
      sortable: true,
      width: 200,
    },
    {
      sub_headers: [],
      tc_code: 2,
      label: "Index columns",
      column_name: "index_columns",
      dimension: "Product",
      type: "str",
      is_frozen: false,
      is_editable: false,
      is_aggregated: false,
      order_of_display: 1,
      is_hidden: false,
      is_required: false,
      tc_mapping_code: 15,
      aggregate_type: "",
      formatter: "",
      is_row_span: false,
      footer: "",
      is_searchable: false,
      isSearchable: true,
      extra: {},
      isMulti: false,
      sortable: false,
      width: 200,
      minWidth: 200,
    },
    {
      sub_headers: [],
      tc_code: 1,
      label: "Unique key",
      column_name: "unique_key",
      dimension: "Product",
      type: "str",
      is_frozen: false,
      is_editable: false,
      is_aggregated: false,
      order_of_display: 1,
      is_hidden: false,
      is_required: false,
      tc_mapping_code: 15,
      aggregate_type: "",
      formatter: "",
      is_row_span: false,
      footer: "",
      is_searchable: false,
      extra: {},
      sortable: true,
      width: 200,
    },
  ],
  partition: [
    {
      sub_headers: [],
      tc_code: 1,
      label: "Name",
      column_name: "partition_name",
      dimension: "Product",
      type: "str",
      is_frozen: false,
      is_editable: false,
      is_aggregated: false,
      order_of_display: 1,
      is_hidden: false,
      is_required: false,
      tc_mapping_code: 15,
      aggregate_type: "",
      formatter: "",
      is_row_span: false,
      footer: "",
      is_searchable: false,
      extra: {},
      sortable: true,
      width: 200,
    },
  ],
  data: [
    {
      sub_headers: [],
      tc_code: 1,
      label: "Data Name",
      column_name: "partition_name",
      dimension: "Product",
      type: "str",
      is_frozen: false,
      is_editable: false,
      is_aggregated: false,
      order_of_display: 1,
      is_hidden: false,
      is_required: false,
      tc_mapping_code: 15,
      aggregate_type: "",
      formatter: "",
      is_row_span: false,
      footer: "",
      is_searchable: false,
      extra: {},
      sortable: true,
      width: 200,
    },
  ],
};

export const metadataCols = {
  metadata: [
    {
      sub_headers: [],
      tc_code: 1,
      label: "Attribute Name",
      column_name: "attribute_name",
      dimension: "Product",
      type: "str",
      is_frozen: false,
      is_editable: false,
      is_aggregated: false,
      order_of_display: 1,
      is_hidden: false,
      is_required: false,
      tc_mapping_code: 15,
      aggregate_type: "",
      formatter: "",
      is_row_span: false,
      footer: "",
      is_searchable: false,
      extra: {},
      sortable: true,
      width: 200,
    },
    {
      sub_headers: [],
      tc_code: 2,
      label: "Attribute Value",
      column_name: "attribute_value",
      dimension: "Product",
      type: "str",
      is_frozen: false,
      is_editable: false,
      is_aggregated: false,
      order_of_display: 1,
      is_hidden: false,
      is_required: false,
      tc_mapping_code: 15,
      aggregate_type: "",
      formatter: "",
      is_row_span: false,
      footer: "",
      is_searchable: false,
      isSearchable: true,
      extra: {},
      isMulti: false,
      sortable: false,
      width: 200,
      minWidth: 200,
    },
  ],
  logs: [
    {
      sub_headers: [],
      tc_code: 1,
      label: "Logs",
      column_name: "logs",
      dimension: "Product",
      type: "str",
      is_frozen: false,
      is_editable: false,
      is_aggregated: false,
      order_of_display: 1,
      is_hidden: false,
      is_required: false,
      tc_mapping_code: 15,
      aggregate_type: "",
      formatter: "",
      is_row_span: false,
      footer: "",
      is_searchable: false,
      extra: {},
      sortable: true,
      width: 200,
    },
  ],

}
