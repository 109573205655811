import {
  SET_JIRA_PROJECTS,
  SET_EPIC,
  SET_STORIES,
  SET_STORIES_DESC,
  SET_QA_PROMPT,
  SET_TEST_CASE,
  SET_TASKS,
  SET_TASK_DESC,
  SET_TASK_TEST_CASE,
  SET_TASK_PROMPT,
} from "../actions/types";

export const initialState = {
  jiraProjectOptions: [],
  epicOptions: [],
  storiesOptions: [],
  storiesDesc: [],
  taskDesc: [],
  qaPromptData: [],
  taskPromptData: [],
  testCaseData: [],
  taskTestCaseData: [],
  promptData: {},
  queryData: {},
  taskList: [],
  pythonPrompt: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_JIRA_PROJECTS:
      return {
        ...state,
        jiraProjectOptions: action.payload,
        epicOptions: [],
        storiesOptions: [],
      };
    case SET_EPIC:
      return {
        ...state,
        epicOptions: action.payload,
        storiesOptions: [],
      };
    case SET_STORIES:
      return {
        ...state,
        storiesOptions: action.payload,
      };
    case SET_STORIES_DESC:
      return {
        ...state,
        storiesDesc: action.payload,
      };
    case SET_TASK_DESC:
      return {
        ...state,
        taskDesc: action.payload,
      };
    case SET_QA_PROMPT:
      return {
        ...state,
        qaPromptData: {...state.qaPromptData, ...action.payload},
      };

    case SET_TASK_PROMPT:
      return {
        ...state,
        taskPromptData: {...state.taskPromptData, ...action.payload},
      };
    case SET_TEST_CASE:
      return {
        ...state,
        testCaseData: {...state.testCaseData, ...action.payload},
      };
    case SET_TASK_TEST_CASE:
      return {
        ...state,
        taskTestCaseData: {...state.taskTestCaseData, ...action.payload},
      };
    case SET_TASKS:
      return {
        ...state,
        taskList: action.payload,
      };
    default:
      return state;
  }
}
