import React from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme)=>({
  myTags:{
    width: "100%",
    border: "1px solid #ACACAC",
    borderRadius: "0.1875rem"
  },
  tags_looks:{
    '& .tagify__dropdown__item' : {
      display: "inline-block",
      verticalAlign: "middle",
      borderRadius: '3px',
      padding: '.3em .5em',
      border: '1px solid #CCC',
      background: '#F3F3F3',
      margin: '.2em',
      fontSize: '.85em',
      color: 'black',
      transition: '0s',
    }
  } 
}))

const ChipsTagInput = (props) => {
  const classes = useStyles();
  // Tagify settings object
  const settings = {
    // pattern: /@/, // <- must define "patten" in mixed mode
    duplicates: true,
    dropdown: {
      enabled: 0,
      position: "text",
    },
    whitelist: props.tagsInfo,
    userInput: false,
    classname: classes.tags_looks
  };

  const onBlur = (e) => {
    props.onBlur(e);
  };
  return (
    <>
      {props.tagsInfo && (
        <>
          <Tags
            settings={settings}
            className={classes.myTags}
            tagifyRef={props.tagifyRef}
            readOnly={props.isReadonly}
            onBlur={onBlur}
            defaultValue={props.value}
            onChange={props.onChange}
          />
          {/* <Typography variant="subtitle1" component="p" align="right">Enter <Typography component="span" color="primary">@</Typography> to use system generated tags.</Typography> */}
        </>
      )}
    </>
  );
};

export default ChipsTagInput;
