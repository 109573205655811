import { makeStyles } from "@mui/styles";
import { pxToRem } from "Utils/functions/utils";
import colours from "./colours";

const globalStyles = makeStyles((theme) => ({
  mainBody: {
    padding: "1.5rem 2rem",
  },
  paper: {
    marginBottom: "1rem",
    padding: "1.5rem 2rem",
    width: "100%",
  },
  paddingVertical: {
    // padding: "1rem 0",
  },
  paddingHorizontal: {
    padding: "0 1rem",
  },
  paddingAround: {
    padding: "0.5rem 1rem",
  },
  gap: {
    gap: "1rem",
  },
  fullWidth: {
    width: "100%",
  },
  widthFitContent: {
    width: "fit-content",
  },
  marginVertical: {
    margin: "0.5rem 0",
  },
  marginVertical1rem: {
    margin: "1rem 0",
  },
  marginHorizontal: {
    margin: "0 1rem",
  },
  marginAround: {
    margin: "1rem",
  },
  marginAuto: {
    margin: "auto",
  },
  marginTop: {
    marginTop: "1rem",
  },
  marginBottom: {
    marginBottom: "1rem",
  },
  filterWrapper: {
    padding: "0 2rem",
  },
  tableWrapper: {
    margin: "2rem",
  },
  paperWrapper: {
    padding: "2rem",
  },
  paperHeader: {
    padding: "1rem",
    borderBottom: `1px solid ${colours.alto}`,
  },
  horizontalBottomLine: {
    borderBottom: `1px solid ${colours.alto}`,
  },
  evenPaddingAround: {
    padding: "1rem",
  },
  positionFixedCenter: {
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: "1",
  },
  scroll: {
    overflow: "scroll",
  },
  auto: {
    overflowX: "auto",
  },
  verticalLabel: {
    marginBottom: "0.3rem",
  },
  label: {
    color: theme.palette.colours.labelColour,
    textTransform: "uppercase",
    lineHeight: "1.5",
  },
  centerAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  minHeightBody: {
    minHeight: "10rem",
  },
  dialogConfirmBox: {
    "& .MuiDialog-paper": {
      minWidth: "430px",
      borderRadius: "10px 10px 6px 6px",
    },
  },
  dialogActionBox: {
    background: "#f7f7f7",
    padding: "1rem",
  },
  dialogTitle: {
    ...theme.typography.h4,
    paddingTop: "1rem",
  },
  dialogText: {
    ...theme.typography.body1,
    paddingTop: "1rem",
  },
  moduleTitle: {
    ...theme.typography.h3,
  },
  dialogContentBody: {
    borderTop: "none",
  },
  flexRow: {
    display: "flex",
  },
  flexColumn: {
    flexDirection: "column",
  },
  displayBlock: {
    display: "block",
  },
  displayNone: {
    display: "none",
  },
  layoutAlignBetweenCenter: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  layoutAlignSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  layoutAlignEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  layoutAlignCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
  alignTextCenter: {
    textAlign: "center",
  },
  verticalAlignCenter: {
    alignItems: "center",
  },
  headerContainer: {
    padding: `${pxToRem(8)} ${pxToRem(21)}`,
  },
  header: {
    color: colours.black,
    font: `normal normal 600 ${pxToRem(18)}/normal Poppins`,
  },
  headerOptions: {
    color: colours.endavour,
    font: `normal normal 500 ${pxToRem(12)}/normal Poppins`,
    marginLeft: pxToRem(24),
    cursor: "pointer",
  },
  filterGroupItemContainer: {
    minWidth: "calc((30%/1.5) - 5px)",
    margin: "0.7rem",
  },
  warningBackground: {
    background: colours.wispPink,
  },
  pageHeader: {
    display: "inline-block",
    fontSize: "1rem",
    lineHeight: "1.5",
    color: theme.palette.colours.codGray,
    letterSpacing: "0px",
    opacity: 1,
  },
  marginVertical2rem: {
    margin: "2rem 0",
  },
  subLabel: {
    fontSize: "0.85rem",
    fontWeight: "100",
    color: theme.palette.textColours.slateGrayLight,
  },
  marginLeft1rem: {
    marginLeft: "1rem",
  },
  // styles for dialog component
  root: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "0.6rem",
    },
  },
  contentBody: {
    minHeight: "20rem",
  },
  accordianWrapper: {
    marginBottom: 15,
  },
  inputLabel: {
    color: theme.palette.colours.filterLabelColor,
    lineHeight: "1.6",
    letterSpacing: "0px",
    opacity: 1,
    fontSize: "0.80rem",
    paddingBottom: "0.4rem",
    display: "inline-block",
  },
  halfWidth: {
    width: "50%",
  },
  toolPanel: {
    fontSize: "1rem",
    height: "2rem",
    paddingLeft: "0.5rem",
    padding: "0.5rem",
    fontWeight: "bold",
  },
  zeroWidth: {
    width: 0,
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));

export default globalStyles;
