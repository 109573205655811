import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Layout from "commonComponents/layout";
import HomePage from "./HomePage.jsx";
import "commonComponents/layout/layout.css";
import { setActiveUserApp } from "actions/sideBarActions";
import { appName } from "config/constants/index.js";

const Home = (props) => {
  const [ userModules, setUserModules ] = useState([])

  // useEffect(async () => {
  //   let userData = await axiosPlatformInstance({
  //     url: `${GET_USER_DETAILS}`,
  //     method: "GET",
  //     // headers: {
  //     //   "tenant-id": "signet"
  //     // }
  //   });
    
  //   let module_id_list = []
  //   userData.data.data.filter(item=>module_id_list.push({"module_name":item.module_name, "role":item.role}))
  //   setUserModules(module_id_list)
  //   sessionStorage.setItem("USER_MODULES",JSON.stringify(module_id_list))
  // },[])
  
  const sideBarOptions = [
    {
      link: "/home",
      title: "Sourcing",
      icon: React.createElement(HomeIcon),
      order: 1,
    },
    // {
    //   link: "/ingestion",
    //   title: "Ingestion",
    //   icon: React.createElement(HomeIcon),
    //   order: 2,
    // },{
    //   link: "/preprocessing",
    //   title: "Preprocessing",
    //   icon: React.createElement(HomeIcon),
    //   order: 3,
    // },{
    //   link: "/serve",
    //   title: "Serve",
    //   icon: React.createElement(HomeIcon),
    //   order: 4,
    // },
    // {
    //   link: "/user",
    //   title: "User",
    //   icon: React.createElement(HomeIcon),
    //   order: 5,
    //   disabled : false
    // },
  ];
  const routes = [
    {
      path: "/home",
      component: HomePage,
      title: "Home"
    },
  ];

  useEffect(() => {
    props.setActiveUserApp(appName.APPLICATION_ACCESS_MANAGEMENT);
    
  }, []);

  return (
    <>
      <Layout
        routes={routes}
        sideBarOptions={sideBarOptions}
        app={appName.APPLICATION_ACCESS_MANAGEMENT}
      />
    </>
  );
};

const mapStateToProps = () => ({});

const mapActionsToProps = { setActiveUserApp };

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Home));
