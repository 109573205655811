import React from "react";
import "./index.scss";
import Navigation from "./components/navigation";
const Filters = (props) => {
  return (
    <div>
      <Navigation />
      {/* <CreateFilter /> */}
    </div>
  );
};

export default Filters;
