import globalStyles from "Styles/globalStyles";
import DateRangePicker from "commonComponents/dateRangePicker";
import { ReactComponent as CloseIcon } from "assets/closeIcon.svg";
import { ReactComponent as AddButtonIcon } from "assets/addButtonIcon.svg";
import { useEffect, useState } from "react";
import "./index.scss";
import moment from "moment";
import { isEmpty } from "lodash";

const SingleDatePicker = (props) => {
  const { startDate: startDateCopy, endDate: endDateCopy, index, onDateChange} = props;
  const [startDate, setStartDate] = useState(startDateCopy);
  const [endDate, setEndDate] = useState(endDateCopy);
  const [focusedInput, setFocusedInput] = useState(null);

  const onFocusChange = (inp) => {
    setFocusedInput(inp);
  };

  const onChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    // Call the update when both the dates are selected.
    if ( !isEmpty(startDate) && !isEmpty(endDate) ) {
      onDateChange(startDate, endDate, index);
    }
  }

  return (
    <DateRangePicker
      startDate={startDate ? moment(startDate) : ""}
      endDate={endDate ? moment(endDate) : ""}
      focusedInput={focusedInput}
      onDatesChange={onChange}
      onFocusChange={onFocusChange}
      showClearDates={false}
      disableType={"disableOnlyPast"}
    />
  );
};

const MultipleDateRangePicker = (props) => {
  const [dateValues, setDateValues] = useState([]);

  const { onCellValueChanged } = props;
  /**
   * removeDatePicker function will be used to
   * remove a date range picker
   */
  const removeDatePicker = (index) => {
    try {
      let values = [...dateValues];
      values?.splice(index, 1);
      onCellValueChanged(values);
      setDateValues(values);
    } catch (error) {
      console.error("removeDatePicker error", error);
    }
  };

  /**
   * addDatePicker function will be used to
   * add new date range picker
   */
  const addDatePicker = () => {
    try {
      let values = [...dateValues];
      let newDates = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
      values.push(newDates);
      onCellValueChanged(values);
      setDateValues(values);
    } catch (error) {
      console.error("addDatePicker error", error);
    }
  };

/**
   * onDateChange function will be used to
   * edit an existing date range.
   */
  const onDateChange = (startDate, endDate, index) => {
    try {
      let values = [...dateValues];
      let newDates = [startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD")];
      values[index] = newDates;
      setDateValues(values);
      onCellValueChanged(values);
    } catch (error) {
      console.error("addDatePicker error", error);
    }
  };

  useEffect(() => {
    isEmpty(props.value) ? setDateValues([]) : setDateValues(props.value);
  }, [props.value]);

  return (
    <div className="date-containers">
      {dateValues?.map((date, index) => (
        <div className="date-containers__single">
          <SingleDatePicker
            startDate={date[0]}
            endDate={date[1]}
            index={index}
            onDateChange={onDateChange}
          />
          <div
            onClick={() => removeDatePicker(index)}
            className="date-containers__button--delete"
          >
            <CloseIcon />
          </div>
        </div>
      ))}
      <div onClick={() => addDatePicker()} className="date-containers__button--add">
        <AddButtonIcon />
      </div>
    </div>
  );
};

export default MultipleDateRangePicker;
