import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "Utils/Loader/loader";
// import { common } from "modules/assortsmart/constants-assortsmart/stringContants";
// import { emailIdValidation } from "./emailValidator";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import globalStyles from "Styles/globalStyles";
import { isNull } from "lodash";
import AgGrid from "Utils/agGrid";
import { getAllFilters } from "actions/filterAction";
import { addSnack } from "../../../../actions/snackbarActions";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import axiosPlatformInstance from "Utils/axiosPlatform";
const common = {
  __Multiple_Value: "(s)",
  __Assortment: "AssortSmart",
  __ConfirmBtnText: "Yes",
  __RejectBtnText: "No",
  __LaterBtnText: "Later",
  __Select_Drops: "Select # of drops",
  __Drop: "Drop",
  __Attribute_Text: "Attribute",
  __Month_Text: "Month",
  __Month_mapping_list: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  __compare_yr_constants: ["LY", "LLY", "LLLY"],
  __seed_with_constants: ["IAF", "LY", "LLY"],
  __number_of_drops: 2,
  __default_column_attributes: {
    is_aggregated: false,
    is_editable: false,
    is_frozen: false,
    is_hidden: false,
    is_required: false,
    is_row_span: false,
    is_searchable: false,
  },
  __create_plan_selling_period_enabled_start_days: ["Sunday"],
  __create_plan_selling_period_enabled_end_days: ["Saturday"],
  __create_plan_seasons: ["Summer", "Fall", "Spring", "Winter"],
  __sub_channel_wholesale: ["Amazon", "Key_Account", "Specialty"],
  __MojoHelpdesk_Link:
    "https://impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/66734",
  __Finalize_Steps: ["2.4", "3"],
  __Plan_stage: { "Working Plan": 0, "Scenario Plan": 2 },
};

const useStyles = makeStyles(() => ({
  form: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: "3rem",
  },
  tableAlignment: {
    textAlign: "center",
    margin: "auto",
    width: "80%",
  },
}));

const BulkValidation = (props) => {
  // using common and individual styles of the component
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [bulkValidateTableColumns, setBulkValidateTableColumns] = useState([]);
  const [bulkValidateTableData, setBulkValidateTableData] = useState([]);
  const [userFormFields, setUserFormFields] = useState([]);
  const [loadBulkValidateColumns, setLoadBulkValidateColumns] = useState(false);

  useEffect(async () => {
    setLoadBulkValidateColumns(true);
    // const formFieldsData = await props.getAllFilters("uam screen");
    if (true) {
      const newFormFields = [
        {
          "fc_code": 280,
          "label": "Add User Name",
          "column_name": "user_name",
          "type": "non-cascaded",
          "display_type": "TextField",
          "level": null,
          "dimension": "product",
          "is_mandatory": true,
          "is_multiple_selection": false,
          "range_min": null,
          "range_max": null,
          "default_value": null,
          "is_disabled": false,
          "is_clearable": false,
          "display_order": 1,
          "is_required": true,
          "extra": {
            "helperText": "Enter a valid user name"
          }
        },
        {
          "fc_code": 280,
          "label": "Add User Id",
          "column_name": "email",
          "type": "non-cascaded",
          "display_type": "TextField",
          "level": null,
          "dimension": "product",
          "is_mandatory": true,
          "is_multiple_selection": false,
          "range_min": null,
          "range_max": null,
          "default_value": null,
          "is_disabled": false,
          "is_clearable": false,
          "display_order": 2,
          "is_required": true,
          "extra": {
            "helperText": "Enter a valid email id"
          }
        }
      ].map((data) => {
        return {
          ...data,
          required: data.is_required,
          error: false,
          accessor: data.column_name,
          field_type: data.display_type,
          value_type: data.column_name === "email" ? "email" : "text",
        };
      });
      setUserFormFields(newFormFields);
      const fetchColumnData = async () => {
        // let cols = await props.getColumns("table_name=add_new_user");
        let cols = agGridColumnFormatter([
          {
            "sub_headers": [],
            "tc_code": 49,
            "label": "Configuration Name",
            "column_name": "config_name",
            "dimension": "User",
            "type": "str",
            "is_frozen": false,
            "is_editable": false,
            "is_aggregated": false,
            "order_of_display": 1,
            "is_hidden": false,
            "is_required": false,
            "tc_mapping_code": 339,
            "aggregate_type": "",
            "formatter": "",
            "is_row_span": false,
            "footer": "",
            "is_searchable": false,
            "extra": {},
            "is_sortable": true,
            "width": 200,
          },
          {
            "sub_headers": [],
            "tc_code": 49,
            "label": props.showIntermediateQueryValidationModal ? "Status" : "Value",
            "column_name": props.showIntermediateQueryValidationModal ? "status" : "value",
            "dimension": "User",
            "type": "str",
            "is_frozen": false,
            "is_editable": false,
            "is_aggregated": false,
            "order_of_display": 2,
            "is_hidden": false,
            "is_required": false,
            "tc_mapping_code": 340,
            "aggregate_type": "",
            "formatter": "",
            "is_row_span": false,
            "footer": "",
            "is_searchable": false,
            "extra": {},
            "is_sortable": true,
            "width": 200
          },
          {
            "sub_headers": [],
            "tc_code": 49,
            "label": "Message",
            "column_name": "message",
            "dimension": "User",
            "type": "str",
            "is_frozen": false,
            "is_editable": false,
            "is_aggregated": false,
            "order_of_display": 3,
            "is_hidden": false,
            "is_required": false,
            "tc_mapping_code": 340,
            "aggregate_type": "",
            "formatter": "",
            "is_row_span": false,
            "footer": "",
            "is_searchable": false,
            "extra": {},
            "is_sortable": true,
            "width": 200
          },
        ])
        setBulkValidateTableColumns(cols);

      };

      const display_for_queries_val = async (data, configKey) => {
        // The key exists, you can access its properties
        configKey = configKey === "intermediate_queries" ? "intermediate_queries_loc" : configKey
        let { value, status, message } = data.data[configKey] || {};

        if (status == false) {
          setBulkValidateTableData([])
          setLoadBulkValidateColumns(false);
          message = "Validation failed for " + value + " " + message
          displaySnackMessages(message || "Validation failed", "error")
        }
        else
          displaySnackMessages(data?.message || "Validation done successfully !", "success")
      }

      const fetchTableData = async () => {
        const { data } = props.showIntermediateQueryValidationModal ? await axiosPlatformInstance({
          url: `${props.dataUrl}`,
          method: "POST",
          data: { validate_config: props.queryPayload }
        }) : await axiosPlatformInstance({
          url: `${props.dataUrl}`,
          method: "GET",
        });
        let queryAttributes = Object.keys(data.data).map(attribute => {
          return Object.keys(data.data[attribute].message).map(attr => {
            return {
              config_name: attr,
              status: data.data[attribute].message[attr].status,
              message: data.data[attribute].message[attr].message
            }
          })
        })
        let configKey = props.location ? "derived_tables_queries_loc" : "intermediate_queries";
        let tableData = props.showIntermediateQueryValidationModal ? queryAttributes[0]
          : Object.keys(data.data).filter(key => (!data.data[key].status && (key !== configKey))).map(key => { return { config_name: key, ...data.data[key] } })
        setBulkValidateTableData(tableData)
        // props.showIntermediateQueryValidationModal ? setBulkValidateTableData(queryAttributes[0])
        // : setBulkValidateTableData(Object.keys(data.data).filter(key=> (!data.data[key].status && (key !== configKey)) ).map(key => {return {config_name:key,...data.data[key]}}))
        setLoadBulkValidateColumns(false);
        if (data?.status) {
          if (props.showIntermediateQueryValidationModal && data.data) {
            display_for_queries_val(data, configKey);
          }
          else
            displaySnackMessages(data?.message || "Validation done successfully !", "success")
        }
        else {
          setBulkValidateTableData([])
          setLoadBulkValidateColumns(false);
          displaySnackMessages(data?.message || "Validation failed", "error")
        }
      }
      fetchColumnData();
      fetchTableData();
    }
  }, []);

  const closeAddNewUserModal = () => {
    props.handleClose();
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  return (
    <>
      <ConfirmPrompt
        showModal={true}
        title={props.showIntermediateQueryValidationModal ? "Intermediate queries validation Report" : "Bulk Validation Report"
        } message=""
        ariaLabeledBy="bulkValdation-dialog"
        primaryBtnText="Save"
        secondaryBtnText="Cancel"
        showCloseIcon={true}
        size="lg"
        hideActionFooter={true}
        confirmCallback={(val) => {
          if (!val) {
            closeAddNewUserModal();
          }
        }}
      >
        <Loader loader={loadBulkValidateColumns}>
          <div className={classes.tableAlignment}>
            <AgGrid
              columns={bulkValidateTableColumns}
              rowdata={bulkValidateTableData}
              sizeColumnsToFitFlag
              uniqueRowId={"email"}
              adjustTableHeightServerSide={true}
            />
          </div>
        </Loader>
      </ConfirmPrompt>
    </>
  );
};

BulkValidation.propTypes = {
  showIntermediateQueryValidationModal: PropTypes.func
};

const mapStateToProps = (store) => {
  return {
    loaderUserMgmt:
      store.tenantUserRoleMgmtReducer.userManagementReducer.loaderUserMgmt,
  };
};

const mapActionsToProps = {
  getAllFilters,
  addSnack
};

export default connect(mapStateToProps, mapActionsToProps)(BulkValidation);

