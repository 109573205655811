import { cloneDeep } from "lodash";
import React from "react";
import Form from "Utils/form/index";
import moment from "moment";

const filterGroupFormWrapper = (props) => {
  const getDefaultFilterValues = () => {
    // update the format of intial selection required by form component
    let cloneInititalSelection = cloneDeep(props.inititalSelection);
    let defaultFilterValues = {};

    cloneInititalSelection.forEach((item) => {
      defaultFilterValues = {
        ...defaultFilterValues,
        [item.filter_id]: item.values,
      };
    });

    return defaultFilterValues;
  };

  const transformFiltersData = () => {
    let cloneFiltersData = cloneDeep(props.filters);
    cloneFiltersData = cloneFiltersData.map((item) => {
      if (item.display_type === "dropdown") {
        item.isClearable = item.is_clearable;
        item.isDisabled = item.is_disabled;
        item.isMulti = item.is_multiple_selection;
      }
      item.options = item.initialData;
      item.accessor = item.column_name;
      item.field_type = item.display_type;
      item.autoSize = false;

      return item;
    });

    return cloneFiltersData;
  };

  const handleChange = (obj, id, field, fieldEvent, initialValue) => {
    // update the parameters required by filtergroup component
    let filterValue;
    const filterAttributes = {
      filter_id: field.filter_keyword,
      filter_type: field.type,
      dimension: field.dimension,
      display_type: field.display_type
    };
    switch (field.field_type) {
      case "DateTimeField":
        filterValue = moment(fieldEvent).format("YYYY-MM-DD");
        break;
      case "dropdown":
        filterValue = fieldEvent.map((opt) => {
          return {
            label: opt.value,
            id: opt.value,
            value: opt.value,
          };
        });
        break;
      case "rangePicker":
        filterValue = [
          fieldEvent[0] ? moment(fieldEvent[0]).format("YYYY-MM-DD") : null,
          fieldEvent[1] ? moment(fieldEvent[1]).format("YYYY-MM-DD") : null,
        ];
        break;
      case "radioGroup":
        filterValue = fieldEvent.target.value;
        break;
      case "fiscalCalendar":
        filterValue = fieldEvent;
        break;
      default:
        filterValue = "";
        break;
    }

    props.updateDependency(filterAttributes, filterValue);
  };

  return (
    <Form
      layout={"vertical"}
      maxFieldsInRow={4}
      handleChange={handleChange}
      fields={transformFiltersData()}
      updateDefaultValue={false}
      defaultValues={getDefaultFilterValues()}
      selectDependency={props.inititalSelection}
      disabledFields={props.disabledFields}
      resetOptions={props.resetOptions}
      dependencyChange={props.dependencyChange}
    ></Form>
  );
};

export default filterGroupFormWrapper;
