import axiosInstance from "../Utils/axios";
import { GET_COLUMNS, SET_ALL_DATA } from "../config/api";
import { SAVE_TABLE_STATE, SAVE_LAST_SEARCH_TAB } from "./types";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";

// ag grid formatter
export const getColumnsAg =
  (queryParams, levelsJson = {}, actions = {}, formatSetAllLabel = false) =>
  async () => {
    // const { data } = await axiosInstance({
    //   url: `${GET_COLUMNS}?${queryParams}`,
    //   method: "GET",
    // });
    return agGridColumnFormatter(
      queryParams === "table_name=user_app_role" ? [
        {
            "sub_headers": [],
            "tc_code": 39,
            "label": "User",
            "column_name": "user_name",
            "dimension": "User",
            "type": "str",
            "is_frozen": true,
            "is_editable": false,
            "is_aggregated": false,
            "order_of_display": 1,
            "is_hidden": false,
            "is_required": false,
            "tc_mapping_code": 254,
            "aggregate_type": "",
            "formatter": "",
            "is_row_span": false,
            "footer": "",
            "is_searchable": true,
            "extra": {},
            "is_sortable": true,
            "width": 200
        },
        {
            "sub_headers": [],
            "tc_code": 39,
            "label": "User id",
            "column_name": "user_code",
            "dimension": "User",
            "type": "int",
            "is_frozen": true,
            "is_editable": false,
            "is_aggregated": false,
            "order_of_display": 2,
            "is_hidden": false,
            "is_required": false,
            "tc_mapping_code": 255,
            "aggregate_type": "",
            "formatter": "",
            "is_row_span": false,
            "footer": "",
            "is_searchable": true,
            "extra": {},
            "is_sortable": true,
            "width": 200
        },
        {
            "sub_headers": [],
            "tc_code": 39,
            "label": "Module",
            "column_name": "module_display_name",
            "dimension": "User",
            "type": "str",
            "is_frozen": false,
            "is_editable": false,
            "is_aggregated": false,
            "order_of_display": 3,
            "is_hidden": false,
            "is_required": false,
            "tc_mapping_code": 187,
            "aggregate_type": "",
            "formatter": "",
            "is_row_span": false,
            "footer": "",
            "is_searchable": true,
            "extra": {},
            "is_sortable": true,
            "width": 200
        },
        {
            "sub_headers": [],
            "tc_code": 39,
            "label": "Roles assigned",
            "column_name": "role",
            "dimension": "User",
            "type": "str",
            "is_frozen": false,
            "is_editable": false,
            "is_aggregated": false,
            "order_of_display": 4,
            "is_hidden": false,
            "is_required": false,
            "tc_mapping_code": 226,
            "aggregate_type": "",
            "formatter": "",
            "is_row_span": false,
            "footer": "",
            "is_searchable": true,
            "extra": {},
            "is_sortable": true,
            "width": 200
        },
        {
            "sub_headers": [],
            "tc_code": 39,
            "label": "Edit",
            "column_name": "edit",
            "dimension": "User",
            "type": "edit_icon",
            "is_frozen": false,
            "is_editable": true,
            "is_aggregated": false,
            "order_of_display": 6,
            "is_hidden": false,
            "is_required": false,
            "tc_mapping_code": 337,
            "aggregate_type": "",
            "formatter": "",
            "is_row_span": false,
            "footer": "",
            "is_searchable": false,
            "extra": {},
            "is_sortable": true,
            "width": 200
        },
        {
            "sub_headers": [],
            "tc_code": 39,
            "label": "Delete",
            "column_name": "delete",
            "dimension": "User",
            "type": "delete_icon",
            "is_frozen": false,
            "is_editable": true,
            "is_aggregated": false,
            "order_of_display": 7,
            "is_hidden": false,
            "is_required": false,
            "tc_mapping_code": 1834,
            "aggregate_type": "",
            "formatter": "",
            "is_row_span": false,
            "footer": "",
            "is_searchable": false,
            "extra": {},
            "is_sortable": true,
            "width": 200
        }
    ] : queryParams === "table_name=user_mgmt" ? [
      {
          "sub_headers": [],
          "tc_code": 39,
          "label": "User",
          "column_name": "user_name",
          "dimension": "User",
          "type": "str",
          "is_frozen": true,
          "is_editable": false,
          "is_aggregated": false,
          "order_of_display": 1,
          "is_hidden": false,
          "is_required": false,
          "tc_mapping_code": 254,
          "aggregate_type": "",
          "formatter": "",
          "is_row_span": false,
          "footer": "",
          "is_searchable": true,
          "extra": {},
          "is_sortable": true,
          "width": 200
      },
      {
        "sub_headers": [],
        "tc_code": 39,
        "label": "Email",
        "column_name": "email",
        "dimension": "Email",
        "type": "str",
        "is_frozen": true,
        "is_editable": false,
        "is_aggregated": false,
        "order_of_display": 1,
        "is_hidden": false,
        "is_required": false,
        "tc_mapping_code": 254,
        "aggregate_type": "",
        "formatter": "",
        "is_row_span": false,
        "footer": "",
        "is_searchable": true,
        "extra": {},
        "is_sortable": true,
        "width": 200
    }
  ] : [],
      levelsJson,
      actions,
      formatSetAllLabel
    );
  };

export const setColumns = (queryParams, dataObj) => async () => {
  return axiosInstance({
    url: `${GET_COLUMNS}/?tableName=${queryParams}`,
    method: "PUT",
    data: dataObj,
  });
};
export const setAllData = (dataObj) => async () => {
  return axiosInstance({
    url: SET_ALL_DATA,
    method: "POST",
    data: dataObj,
  });
};

export const setTableState = (payload) => (dispatch) => {
  dispatch({
    type: SAVE_TABLE_STATE,
    payload: payload,
  });
};

export const setColumnsConfiguration = (dataObj) => async () => {
  return axiosInstance({
    url: `${GET_COLUMNS}/user-preference`,
    method: "POST",
    data: dataObj,
  });
};

export const setSaveSearchConfig = async (dataObj) => {
  return axiosInstance({
    url: "core/table-fields/user-search-preference",
    method: "POST",
    data: dataObj,
  });
}

export const setLastSearchType = (payload) => (dispatch) => {
  dispatch({
    type: SAVE_LAST_SEARCH_TAB,
    payload: payload,
  });
}