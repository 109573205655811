import {
  CHANGE_FILTER_DATA,
  SET_SELECTED_FILTERS,
  SET_SELECTED_FILTER_CONFIGURATION,
  SET_SAVED_FILTER_SELECTION,
  RESET_SELECTED_FILTER_CONFIGURATION,
  SET_IS_SAVED_FILTER_MAPPING_INVALID,
  RESET_SET_IS_SAVED_FILTER_MAPPING_INVALID,
  GET_SAVED_FILTERSELECTION_LOADING,
  SET_APPLICATION_CODES_LIST,
} from "../actions/types";

export const initialState = {
  filterInitialData: [],
  selectedFilters: {},
  filterDashboardConfiguration: {},
  savedFilterSelection: [],
  getSavedFilterSelectionLoading: true,
  isSavedFilterMappingInValid: [],
  isSavedFilterMappingValid: [],
  applicationCodesList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_FILTER_DATA:
      return {
        ...state,
        filterInitialData: action.payload,
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: { ...state.selectedFilters, ...action.payload },
      };
    case SET_SELECTED_FILTER_CONFIGURATION:
      return {
        ...state,
        filterDashboardConfiguration: {
          ...state.filterDashboardConfiguration,
          ...action.payload,
        },
      };
    case GET_SAVED_FILTERSELECTION_LOADING:
      return {
        ...state,
        getSavedFilterSelectionLoading: action.payload,
      };
    case SET_SAVED_FILTER_SELECTION:
      return {
        ...state,
        savedFilterSelection: action.payload,
        getSavedFilterSelectionLoading: false,
      };
    case RESET_SELECTED_FILTER_CONFIGURATION:
      return {
        ...state,
        filterDashboardConfiguration: action.payload,
      };
    case SET_IS_SAVED_FILTER_MAPPING_INVALID: {
      if (!action.payload.isInvalid) {
        return {
          ...state,
          isSavedFilterMappingValid: [
            ...state.isSavedFilterMappingValid,
            action.payload.filterConfigName,
          ],
        };
      } else {
        return {
          ...state,
          isSavedFilterMappingInValid: [
            ...state.isSavedFilterMappingInValid,
            action.payload.filterConfigName,
          ],
        };
      }
    }
    case RESET_SET_IS_SAVED_FILTER_MAPPING_INVALID:
      return {
        ...state,
        isSavedFilterMappingInValid: [],
        isSavedFilterMappingValid: [],
      };
    case SET_APPLICATION_CODES_LIST:
      return {
        ...state,
        applicationCodesList: action.payload,
      };
    default:
      return state;
  }
}
