import { isEmpty } from "lodash";
import store from "store";
// import { attributeFormatter } from "../modules/assortsmart/utils-assortsmart/utilityFunctions";

const attributeFormatter = function (value) {
  //isPrice key is used for inserting dollar in value formatter

  //If value is not a valid type, return empty string
  if (!value) {
    return "";
  }

  //If value is not a numerical type, return by camel casing the text
  let letter = /[a-zA-Z]/g;
  if (value.match(letter)) {
    return value.length < 4
      ? value
          .replace(" penetration", "")
          .split("_")
          .map((setAllfield) => {
            //If setAll field contains term "cc",e.g max_cc whole term needs to be converted to uppercase
            if (setAllfield === "cc" || setAllfield === "aur")
              return setAllfield.toUpperCase();
            return (
              setAllfield.charAt(0).toUpperCase() +
              setAllfield.slice(1).toUpperCase() +
              " "
            );
          })
          .join("")
      : value
          .replace(" penetration", "")
          .split("_")
          .map((setAllfield) => {
            //If setAll field contains term "cc",e.g max_cc whole term needs to be converted to uppercase
            if (setAllfield === "cc" || setAllfield === "aur")
              return setAllfield.toUpperCase() + " ";
            return (
              setAllfield.charAt(0).toUpperCase() + setAllfield.slice(1) + " "
            );
          })
          .join("");
  }

  //If value is in numerical format
  return value
    .replace(" penetration", "")
    .replace("_", " ")
    .split("_")
    .map((e) => {
      let temp = e.split(" ");
      temp[0] = parseFloat(temp[0]) >= 0 ? parseInt(temp[0]).toString() : "";
      temp[1] = parseFloat(temp[1]) >= 0 ? parseInt(temp[1]).toString() : "";
      return temp[1].length ? temp[0] + " - " + temp[1] : temp[0];
    })
    .join("");
};

export const fetchDynamicConfigFromTenantReducer = (
  application,
  requiredAttribute
) => {
  // This function takes application name and attribute name as parameters to search for
  // the corresponding attribute mappings
  // We are storing the config values of each application in reducer service to access it
  // accross the modules. As we say for client to client, labels for product code keeps changing
  // we have configured in the db those mappings. After fetching from the db, we are storing
  // them in application related reducer.
  // After storing we fetch the values based on the attribute key
  // Example, dynamicLabels is used for the label mappings and dynamicLabelKeys is used for
  // unique key mappings
  let dynamicTenantData =
    application === "core"
      ? store.getState()?.tenantConfigReducer?.["coreScreenNames"]?.[
          "attribute_value"
        ]?.[requiredAttribute] //requiredAttribute will be dynamicLabels/dynamicLabelKeys
      : store.getState()?.inventorysmartReducer?.inventorySmartCommonService
          ?.inventorysmartScreenConfig?.[requiredAttribute]; // To be made more expansive for other products
  return dynamicTenantData;
};

export const dynamicLabelsBasedOnTenant = (key, application) => {
  if (!isEmpty(store?.getState())) {
    let dynamicLabels = fetchDynamicConfigFromTenantReducer(
      application,
      "dynamicLabels"
    );
    return dynamicLabels?.[key] || attributeFormatter(key); //If in case that config isnt present on the DB layer, then display the key itself
  } else return "";
};

export const dynamicLabelKeysBasedOnTenant = (key, application) => {
  if (!isEmpty(store.getState())) {
    let dynamicLabelKeys = fetchDynamicConfigFromTenantReducer(
      application,
      "dynamicLabelKeys"
    );
    return dynamicLabelKeys?.[key] || key; //If in case that config isnt present on the DB layer, then display the key itself
  }
};
