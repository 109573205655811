import moment from "moment";
import axiosPlatformInstance from "Utils/axiosPlatform";

export const formItemValueAfterValidation = (item, value) => {
    return {
      ...item,
      error: value,
    };
  };

export const getTenantFilterConfig = async (URL,setLoadingStatus,addSnack) => {
    try {
      setLoadingStatus(true);
      const { data } = await axiosPlatformInstance({
        url: `${URL}`,
        method: "GET",
      });
      setLoadingStatus(false);
      return data;
    } catch (e) {
      setLoadingStatus(false);
      if (e.response.status === 403) {
        displaySnackMessages(addSnack,e.response.data.message, "error");
      } else {
        displaySnackMessages(addSnack,"Something went wrong", "error");
      }
    }
  };

export const displaySnackMessages = (addSnack, message, variance) => {
    addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

export const handleUploadCSV = async (parsedData, file,cols,uploadHandler,validationHandler, addSnack) => {
  if (parsedData && parsedData.length > 0) {
      let validCSV = true;
      const missing_cols_list = []
      const existing_cols = []
      Object.keys(parsedData[0]).forEach((col) => {
        if (!cols.includes(col)) {
          validCSV = false;
        }
        else
          existing_cols.push(col)
      });
      cols.map(col=>{
        if(!existing_cols.includes(col))
          missing_cols_list.push(col)
      })
      if (!validCSV || missing_cols_list?.length) {
        addSnack({
          message: missing_cols_list?.length ? missing_cols_list.join(", ")+" columns are missing in the selected file" : "Please re-upload correct file.",
          options: {
            variant: "error",
          },
        });
        validationHandler.current.validate([]);
      } else {
        uploadHandler(file)
      }
    } else {
      addSnack({
        message:
          "File doesn't contain any data. Please re-upload correct file.",
        options: {
          variant: "error",
        },
      });
      validationHandler.current.validate([]);
    }
  };

export const DownloadJSON = (Data, filename) => {
  const dataStr =
    "data:application/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(Data));
  const download = document.createElement("a");
  download.setAttribute("href", dataStr);
  download.setAttribute("download", filename + ".json");
  document.body.appendChild(download);
  download.click();
  download.remove();
};

export const DownloadSQL = (Data, filename) => {
  const dataStr =
    "data:application/sql;charset=utf-8," + encodeURIComponent(Data);
  const download = document.createElement("a");
  download.setAttribute("href", dataStr);
  download.setAttribute("download", filename + ".sql");
  document.body.appendChild(download);
  download.click();
  download.remove();
};

export const isJson = (item) => {
  let value = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === "object" && value !== null;
};
  export const cell_renderer = (col,options) =>{
    if (col.column_name === "created_at") {
      col.accessor = "created_at";
      col.field = "created_at";
      col.cellRenderer = (params) => {
        return (
          <>{moment(params.data.created_at).format("MMM Do YY, hh:mm a")}</>
        );
      };
    }
    if (col.column_name === "source_config") {
      col.options = options.source_config_options;
    }
    if (col.column_name === "updated_at") {
      col.accessor = "updated_at";
      col.field = "updated_at";
      col.cellRenderer = (params) => {
        return (
          <>
            {params.data.updated_at
              ? moment(params.data.updated_at).format("MMM Do YY, hh:mm a")
              : ""}
          </>
        );
      };
    }
  }

  export const handleDownloadCSVTemplate = (headerContent) => {
    const csvContent = headerContent;
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'template.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
};
