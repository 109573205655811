import React, { Component } from "react";
import {
  getFiscalWeekArray,
  getFiscalWeekAndYear,
} from "./calendarHelperFuncs";
import moment from "moment";
import _ from "lodash";
import { calendarConfig } from "config";
import "./NrfDatePicker.css";
const { fstMoOfYr } = calendarConfig;
const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const nrfWksPatternForAYr = [4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 4, 5];
const moJanIdx = 0;
const _4Wks = 4;
const _5Wks = 5;
const nrfCalYrs = {};

function generateNrfCalYrs(yr, nrfCalYrs) {
  const noOfDaysInAWk = days.length;
  const noOfMosInAYr = nrfWksPatternForAYr.length;
  const stDt = moment().year(yr).week(1).startOf("week");

  const doesJanHaveFiveWks = moment().year(yr).weeksInYear() === 53;

  doesJanHaveFiveWks && (nrfWksPatternForAYr[moJanIdx] = _5Wks);

  // Inserting Dates by forward calculating from the start date
  let nxtDt = moment(stDt);
  for (let moIdx = fstMoOfYr; moIdx < noOfMosInAYr + fstMoOfYr; moIdx++) {
    !nrfCalYrs[yr] && (nrfCalYrs[yr] = {});
    moIdx > 11 && !nrfCalYrs[yr + 1] && (nrfCalYrs[yr + 1] = {});
    const momentMoIdx = moment().month(moIdx).month();
    const moDts = [];
    for (let nrfWks = 0; nrfWks < nrfWksPatternForAYr[momentMoIdx]; nrfWks++) {
      for (let dayIdx = 0; dayIdx < noOfDaysInAWk; dayIdx++) {
        if (dayIdx === 0) {
          moDts[nrfWks] = [];
        }
        moDts[nrfWks].push(moment(nxtDt));
        nxtDt = nxtDt.add(1, "days");
      }
    }
    moIdx < 12
      ? (nrfCalYrs[yr][momentMoIdx] = moDts)
      : (nrfCalYrs[yr + 1][momentMoIdx] = moDts);
  }

  // Setting no of weeks for Jan back to default
  doesJanHaveFiveWks && (nrfWksPatternForAYr[moJanIdx] = _4Wks);
  return nrfCalYrs;
}

const Calendar = function (props) {
  //reporting screen: for Monday to Sunday selection --- <  startDay = 0,endDay = 6;  >---
  //other screens: for Thursday to Wednesday selection --- <  startDay = 3,endDay = 2;  >---
  let startDay =
    props.calendarRange && props.calendarRange.day_start
      ? props.calendarRange.day_start
      : 0;
  let endDay =
    props.calendarRange && props.calendarRange.day_end
      ? props.calendarRange.day_end
      : 6;
  // fiscal calendar detail from api
  let fiscalCalendarDetails = props.fiscalCalendarDetails;

  const getCalendarCellStyle = (dt, stDt, endDt) => {
    let className = "calendar-date_default";
    if (props.pickJustDate) {
      if (props.startDate && !props.endDate) {
        dt.isSame(props.startDate, "day") && (className = "selected-date");
        dt.isAfter(props.startDate, "day") &&
          dt.isSameOrBefore(props.mouseOveredDt, "day") &&
          (className = "calendar-date_hovered");
      } else if (props.startDate && props.endDate) {
        (dt.isSame(props.startDate, "day") ||
          dt.isSame(props.endDate, "day")) &&
          (className = "selected-date");
        dt.isAfter(props.startDate, "day") &&
          dt.isBefore(props.endDate, "day") &&
          (className = "calendar-date_hovered");
        (dt.isBefore(props.startDate, "day") ||
          dt.isAfter(props.endDate, "day")) &&
          dt.isSame(props.mouseOveredDt, "day") &&
          (className = "calendar-date_default-hover");
      } else {
        dt.isSame(props.mouseOveredDt, "day") &&
          (className = "calendar-date_default-hover");
      }
    } else {
      if (props.startDate && !props.endDate) {
        dt.isSame(props.startDate) && (className = "selected-date");
        // if start date is selected and not end date yet, we will capture the whole week,
        // when you hover over the dates, you will see the same effect
        dt.isAfter(props.startDate) &&
          dt.isSameOrBefore(props.mouseOveredDt) &&
          (className = "calendar-date_hovered");
      } else if (props.startDate && props.endDate) {
        (dt.isSame(props.startDate) || dt.isSame(props.endDate)) &&
          (className = "selected-date");
        dt.isAfter(props.startDate) &&
          dt.isBefore(props.endDate) &&
          (className = "calendar-date_hovered");
        props.mouseOveredDt &&
          (dt.isBefore(props.startDate) || dt.isAfter(props.endDate)) &&
          props.mouseOveredDt.isBetween(
            moment(stDt).subtract(1, "days"),
            moment(endDt).add(1, "days")
          ) &&
          (className = "calendar-date_default-hover");
      } else {
        // current mouse over date
        props?.mouseOveredDt?.isBetween(
          moment(stDt).subtract(1, "days"),
          moment(endDt).add(1, "days")
        ) && (className = "calendar-date_default-hover");
      }
    }
    return className;
  };
  return (
    <div>
      <div className="t-a-c mt-2 currentDate">
        {props.month.format("MMM")} {props.month.format("YYYY")}
      </div>
      <table className="m-3">
        <tbody>
          <tr>
            <th></th>
            {/* day names of Week */}
            {props.nrfCalDays &&
              props.nrfCalDays[0] &&
              _.map(props.nrfCalDays[0], (day, idx) => (
                <th className="calendar-cell-size calendar-day" key={idx}>
                  {day.format("dd")}
                </th>
              ))}
          </tr>
          {props.nrfCalDays &&
            _.map(props.nrfCalDays, (week, idx) => (
              <tr key={idx}>
                {/* Fiscal week number */}
                <th className="calendar-cell-size calendar-date">
                  {getFiscalWeekAndYear(
                    props.nrfCalDays[idx][0].year(),
                    props.nrfCalDays[idx][0].week(),
                    fiscalCalendarDetails,
                    true
                  )}
                </th>
                {week.map((dt, idx) => {
                  return (
                    <td
                      key={idx}
                      onClick={() => {
                        if (!props.pickJustDate) {
                          if (!props.startDate) {
                            props.onClick(week[startDay]);
                            // As you hover over new row in calendar, the date will be getting adding up
                            // in multiple of weeks, same can be seen when you hover over the dates.
                          } else if (props.totalNumOfWeeksToBeSelected) {
                            let sDate = week[startDay];
                            let eDate = moment(week[startDay])
                              .add(props.totalNumOfWeeksToBeSelected, "weeks")
                              .day(startDay);
                            let data = getFiscalWeekArray(
                              sDate,
                              eDate,
                              fiscalCalendarDetails
                            );
                            props.onClick(
                              sDate,
                              data.weekNumList,
                              data.yearList,
                              eDate
                            );
                          } else {
                            if (dt.isBefore(props.startDate)) {
                              let data = props.endDate
                                ? getFiscalWeekArray(
                                    week[startDay],
                                    props.endDate,
                                    fiscalCalendarDetails
                                  )
                                : {};
                              props.onClick(
                                week[startDay],
                                data.weekNumList,
                                data.yearList
                              );
                            } else if (
                              (startDay !== 0 &&
                                //do not allow selection from same week in case of Thu to Wed selection
                                moment(week[endDay]).week() !==
                                  moment(props.startDate).week()) ||
                              startDay === 0
                            ) {
                              let data = getFiscalWeekArray(
                                props.startDate,
                                week[endDay],
                                fiscalCalendarDetails
                              );
                              props.onClick(
                                week[endDay],
                                data.weekNumList,
                                data.yearList
                              );
                            }
                          }
                        } else {
                          props.onClick(dt);
                        }
                      }}
                      onMouseOver={() => {
                        if (!props.pickJustDate) {
                          if (props.startDate) {
                            if (dt.isSameOrAfter(props.startDate)) {
                              props.onMouseOver(week[endDay]);
                            } else {
                              props.onMouseOver(week[startDay]);
                            }
                          } else {
                            props.onMouseOver(week[startDay]);
                          }
                        } else {
                          props.onMouseOver(dt);
                        }
                      }}
                      className={`calendar-cell-size ${getCalendarCellStyle(
                        dt,
                        week[0],
                        week[6]
                      )} calendar-date`}
                    >
                      {dt.date()}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

class MultipleCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOveredDt: null,
    };
  }
  render() {
    return (
      <div style={{ display: "flex" }}>
        {this.props.months.map((month, idx) => {
          return (
            <Calendar
              key={idx}
              month={month}
              onClick={this.props.onClick}
              onMouseOver={(dt) => {
                this.setState({
                  mouseOveredDt: dt,
                });
              }}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              mouseOveredDt={this.state.mouseOveredDt}
              disableOldWeeks={this.props.disableOldWeeks}
              disableFutureWeeks={this.props.disableFutureWeeks}
              nrfCalDays={
                this.props.nrfCalYrs[month.year()] &&
                this.props.nrfCalYrs[month.year()][month.month()]
              }
              fiscalCalendarDetails={this.props.fiscalCalendarDetails}
              pathname={this.props.pathname}
              calendarRange={this.props.calendarRange}
              pickJustDate={this.props.pickJustDate}
              totalNumOfWeeksToBeSelected={
                this.props.totalNumOfWeeksToBeSelected
              }
            />
          );
        })}
      </div>
    );
  }
}
// Level 1 of NRF calendar
class RetailCalendar extends Component {
  constructor(props) {
    super(props);
    //current date
    const curDt = moment();
    // no. of months to see on calendar
    const dispMosCnt = props.displayMonth ? props.displayMonth : 2; //min:1, max:12
    const width = { width: `${344 * dispMosCnt}px` };
    let dispMos = [];
    // generateNrfCalYrs : Creates calendar
    //nrfCalYrs creates dynamic calendar for the particular year
    generateNrfCalYrs(curDt.year(), nrfCalYrs);
    // on 11 month, next year calendar data is generated
    if (curDt.month() === 11) generateNrfCalYrs(curDt.year() + 1, nrfCalYrs);
    for (let i = 0; i < dispMosCnt; i++) {
      dispMos.push(moment(curDt).add(i, "month"));
    }
    let fyear = curDt.year();
    if (curDt.month() === 11) {
      fyear = curDt.year() + 1;
    }
    if (curDt.month() === 0) {
      fyear = curDt.year() - 1;
    }

    this.state = {
      dispMos,
      curDt,
      financialYear: moment().year(),
      width,
    };
  }

  // on prev arrow month click
  onPrevMoClick = () => {
    const fstMo = this.state.dispMos[0];
    if (fstMo.month() === fstMoOfYr) {
      this.setState(
        {
          financialYear: fstMo.year(),
        },
        () => this.onPrevYrClick()
      );
    }
    const sftdMosBkByOneMo = this.state.dispMos.map((month) => {
      return month.subtract(1, "month");
    });
    this.setState({ dispMos: sftdMosBkByOneMo });
  };

  // on next arrow month click
  onNxtMoClick = () => {
    const { dispMos } = this.state;
    const lstMo = dispMos[dispMos.length - 1];
    const lstMoOfYr = moment()
      .month(fstMoOfYr - 1)
      .month();
    if (lstMo.month() === lstMoOfYr) {
      this.onNxtYrClick();
    }
    const sftdMosFwdByOneMo = this.state.dispMos.map((month) => {
      return month.add(1, "month");
    });
    this.setState({ dispMos: sftdMosFwdByOneMo });
  };

  // on prev year, dynamic change, no click invloved
  onPrevYrClick = () => {
    const { financialYear: yr } = this.state;
    const prevYr = yr - 1;
    const nxtYr = yr + 1;
    generateNrfCalYrs(prevYr, nrfCalYrs);
    this.setState({ financialYear: prevYr });
    if (nrfCalYrs.hasOwnProperty(nxtYr)) delete nrfCalYrs[nxtYr];
  };

  // on next year, dynamic change, no click invloved
  onNxtYrClick = () => {
    const { financialYear: yr } = this.state;
    const nxtYr = yr + 1;
    generateNrfCalYrs(nxtYr, nrfCalYrs);
    this.setState({ financialYear: nxtYr });
  };
  render() {
    return (
      <div className="m-3" style={{ ...this.state.width }}>
        <div className="arrow left-arrow">
          <i
            onClick={this.onPrevMoClick}
            className="dr-calendar-month-arrow fa fa-angle-left"
            aria-hidden="true"
          ></i>
        </div>
        <div className="arrow right-arrow">
          <i
            onClick={this.onNxtMoClick}
            className="dr-calendar-month-arrow fa fa-angle-right"
            aria-hidden="true"
          ></i>
        </div>

        <MultipleCalendar
          months={this.state.dispMos}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          nrfCalYrs={nrfCalYrs}
          onClick={(dt, ...args) => {
            if (dt.month() === 11) this.onNxtYrClick();
            this.props.onClick(dt, ...args);
          }}
          fiscalCalendarDetails={this.props.fiscalCalendarDetails}
          pathname={this.props.location && this.props.location.pathname}
          calendarRange={this.props.calendarRange}
          disableOldWeeks={this.props.disableOldWeeks}
          disableFutureWeeks={this.props.disableFutureWeeks}
          pickJustDate={this.props.pickJustDate}
          totalNumOfWeeksToBeSelected={this.props.totalNumOfWeeksToBeSelected}
        />
      </div>
    );
  }
}

export default RetailCalendar;
