import axiosInstance from "../Utils/axios";
import { GET_HOME_APPLICATION } from "config/api";
import { SET_USER_APPS } from "./types";

export const homePageActions = () => async (dispatch) => {
  let data = await axiosInstance({
    url: `${GET_HOME_APPLICATION}`,
    method: "GET",
  });
  const userApps = data.data.data.map((e) => e.application);
  dispatch({
    type: SET_USER_APPS,
    payload: userApps,
  });

  // let data = await axiosPlatformInstance({
  //   url: `${GET_USER_DETAILS}`,
  //   method: "GET",
  //   // headers: {
  //   //   "tenant-id": "signet"
  //   // }
  // });
  // const userApps = data.data.data.map((e) => e.module_display_name);
  // const userAppsPermission = data.data.data
  
  // dispatch({
  //   type: SET_USER_APPS_PERMISSION,
  //   payload: userAppsPermission
  // })
};

// export const getUserDetails = () => async (dispatch) => {
//   let data = await axiosPlatformInstance({
//     url: `${GET_USER_DETAILS}`,
//     method: "GET",
//   });
//   const userModules = data.data.data.map((e) => e.application);
//   dispatch({
//     type: SET_USER_DETAILS,
//     payload: userModules,
//   });
// };

