import {
  getAllFilters,
  getFiltersValues,
  getCombinedCrossDimensionFiltersData,
  getCombinedFiltersValues,
} from "actions/filterAction";
import { getColumnsAg } from "actions/tableColumnActions";
// import { fetchVendorData } from "actions/vendorActions";
import {
  kebabCase,
  cloneDeep,
  isEmpty,
  isUndefined,
  find,
  difference,
  uniq,
  isNil,
} from "lodash";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import { captializeStringIfCamelCase } from "Utils/formatter";
import store from "store";
import { addSnack } from "actions/snackbarActions";
import { getCurrentApplicationName } from "Utils/functions/utils";
import { getUrmFilters } from "pages/tenant-config/access-user-management/services/TenantManagement/User-Role-Management/user-role-management-service";

export const getTenantTimeZoneDetails = () => {
  if (!isEmpty(store?.getState())) {
    const tenantDateFormat = store.getState()?.tenantUserRoleMgmtReducer
      ?.userRoleManagementReducer.tenantDateFormat;
    const tenantTimeZone = store.getState()?.tenantUserRoleMgmtReducer
      .userRoleManagementReducer.tenantTimeZone;
    return {
      tenantDateFormat,
      tenantTimeZone,
    };
  } else return { tenantDateFormat: "", tenantTimeZone: "" };
};

// returns the current application name and code
export const getCurrentApplicationDetails = () => {
  const applicationName = getCurrentApplicationName();

  if (!isEmpty(store?.getState())) {
    const applicationCodesList = store.getState()?.filterReducer
      ?.applicationCodesList;
    const applicationCode = find(applicationCodesList, (application) => {
      return application.name.toUpperCase() === applicationName.toUpperCase();
    });

    return {
      applicationCode: applicationCode?.application_code,
      applicationName,
    };
  } else return { applicationCode: "", applicationName: "" };
};

export const getRequiredFilterList = (filterData, filterDependency) => {
  const requiredFilters = filterData
    .filter((filter) => filter.is_mandatory)
    .map((item) => {
      return {
        attribute_name: item.column_name,
        filter_type: item.type,
      };
    });
  let copyFilterDependency = cloneDeep(filterDependency);

  const saveFilterList = copyFilterDependency.filter((filter) => {
    const requiredFilter = find(requiredFilters, {
      attribute_name: filter.attribute_name,
    });
    if (requiredFilter) {
      // this makes sure that we are retaining the "cascaded" or "non-cascaded" type from filterData
      filter.filter_type = requiredFilter.filter_type;
      return true;
    }
    return false;
  });
  return saveFilterList;
};

export const checkIsFilterDependencyValid = async (
  initialDependency,
  uamScreenName
) => {
  // check if initialDependency data is valid in filter dashboard

  // get the complete set of the initialDependency and check if depedency value is present in the super set
  // if value is not present discard the complete initialDependency and set as empty []
  // if all values in initialDependency pass the check forward the initialDependency as it is.

  let isDependencyInvalid = false;

  if (!isEmpty(initialDependency)) {
    let filtersList = initialDependency.map((filter) => {
      return {
        column_name: filter.attribute_name,
        dimension: filter.dimension,
      };
    });

    const filterDashboardData = await getCombinedFilterDashboardData(
      filtersList,
      [],
      uamScreenName
    );

    for (const dependency of initialDependency) {
      let options = filterDashboardData[dependency.attribute_name];
      isDependencyInvalid = difference(dependency.values, options).length !== 0;

      if (isDependencyInvalid) {
        break;
      }
    }
  }

  return isDependencyInvalid;
};

// returns attribute and dimension list for filter fetch request
export const getfilterAttributeList = (filtersList) => {
  return filtersList.map((filter) => {
    return {
      attribute_name: filter.column_name,
      dimension: filter.dimension,
    };
  });
};

// combined filter values fetch call based on parameters and isCrossDimension key
export const getCombinedFilterDashboardData = async (
  filtersList,
  initialDependency,
  uamScreenName = ""
) => {
  try {
    const { applicationCode } = getCurrentApplicationDetails();
    const attributesList = getfilterAttributeList(filtersList);

    let body = {
      attributes: attributesList,
      filter_type: "cascaded",
      filters: initialDependency,
    };

    if (!isEmpty(uamScreenName)) {
      body.is_urm_filter = true;
      body.screen_name = uamScreenName;
      body.application_code = applicationCode;
    }

    const filterElementsData = await getCombinedCrossDimensionFiltersData(
      body
    )();

    return filterElementsData.data.data;
  } catch (error) {
    if (error.response?.data?.message) {
      displaySnackMessages(error.response?.data?.message, "error");
    } else {
      displaySnackMessages("Something went wrong", "error");
    }
    return [];
  }
};

// fetch filter dashboard data and filter field dropdown values
export const fetchFilterFieldValues = async (
  // to destructure later
  screenName,
  initialDependency = [],
  uamScreenName
) => {
  let response = await getAllFilters(screenName)();
  initialDependency = getRequiredFilterList(
    response.data.data,
    initialDependency
  );

  const isFilterDependencyMappingInvalid = await checkIsFilterDependencyValid(
    initialDependency,
    uamScreenName
  );

  if (isFilterDependencyMappingInvalid) {
    initialDependency = [];
  }

  const filterDashboardData = await getCombinedFilterDashboardData(
    response.data.data,
    initialDependency,
    uamScreenName
  );

  const filterElements = response.data.data.map((key) => {
    const options = filterDashboardData[key.column_name]
      ? filterDashboardData[key.column_name]
      : [];
    key.initialData = options.map((item) => {
      return mapDataToLabel(item);
    });
    key.mappedKey = getMappedKeyForFilter(filterDashboardData, key.column_name);
    key.filter_keyword = key.column_name;
    return key;
  });
  return filterElements;
};

export const getMappedKeyForFilter = (filterDashboardData, columnName) => {
  if (filterDashboardData["mapping_key"]) {
    if (filterDashboardData["mapping_key"][columnName]) {
      return filterDashboardData["mapping_key"][columnName];
    }
  }
  return "";
};

// fetch table columns
export const fetchTableColumnData = async (tableName, optionsList = {}) => {
  let colData = await getColumnsAg(`${"table_name="}${tableName}`)();
  if (optionsList) {
    const keys = Object.keys(optionsList);
    colData = colData.map((item) => {
      if (keys.includes(item.column_name)) {
        item.options = optionsList[item.column_name];
        item.disabled = true;
      }
      return item;
    });
  }
  return colData;
};

// fetch vendor status table data
export const fetchTableData = async (body, url) => {
  // let res = await fetchVendorData(body, url)();
  let res = {}
  res.data.data = res.data.data.map((item) => {
    item.vendor_id = `${item.vendor_code}${item.product_code}`;
    return item;
  });
  return res;
};

// based on the depedency sent and user level hierarchy
// return the updated filters dependency list for uam
export const getUamFilterDependency = async (
  filterDependency,
  screenName,
  expectedFilterDimensions
) => {
  const { applicationCode } = getCurrentApplicationDetails();
  const urmFilterList = await getUrmFilters({
    application: applicationCode,
    screen_name: screenName,
  })();

  const filterDependencyFields = filterDependency.map((filter) => {
    return filter.attribute_name;
  });
  const urmFilterData = urmFilterList.filter(
    (filter) =>
      !filterDependencyFields.includes(filter.attribute_name) &&
      expectedFilterDimensions.includes(filter.dimension)
  );
  const uamFilterDependency = getSelectionDependency(urmFilterData);
  return [...filterDependency, ...uamFilterDependency];
};

export const convertFilterDataToDependency = (filterData) => {
  const filterDependency = filterData.map((filter) => {
    return {
      attribute_name: filter.column_name,
      dimension: filter.dimension,
      display_type: filter.display_type,
      filter_id: filter.column_name,
      filter_type: filter.type,
      values: filter.initialData.map((filterValue) => {
        return filterValue.id;
      }),
      operator: "in",
    };
  });

  return filterDependency;
};

export const updateDependencyData = (item) => {
  let body = {
    filter_id: item.filter_id || item.attribute_name,
    attribute_name: item.filter_id || item.attribute_name,
    operator: "in",
    dimension: item.dimension,
    values: Array.isArray(item.values)
      ? item.values.map((opt) => {
          if (isUndefined(opt?.value)) {
            return opt;
          }
          return opt.value;
        })
      : item.values,
    filter_type: item.filter_type,
    display_type: item.display_type,
  };

  return body;
};

export const mapDataToLabel = (item) => {
  if (typeof item === "boolean") {
    let toCaps = item.toString().toUpperCase();
    return {
      value: item,
      label: toCaps,
      id: item,
    };
  } else {
    return {
      value: item,
      label: item,
      id: item,
    };
  }
};

export const getSelectionDependency = (dependency) => {
  let selectionDependency =
    dependency.length > 0
      ? dependency.map((item) => {
          return updateDependencyData(item);
        })
      : [];

  return selectionDependency;
};

// update filter field dropdowns based on cascading
export const updateFilterData = async (
  dependency,
  filterData,
  isCrossDimensionFilter = false,
  filterDependency,
  filterDimension,
  is_urm_filter,
  screen_name,
  application_code,
  filterSelected
) => {
  dependency = getAllDimensionDependency(
    filterDimension,
    dependency,
    filterDependency
  );

  let selectionDependency = getSelectionDependency(dependency);
  try {
    let initialFilterElements = [...filterData];
    let mappedKeys = {};
    // filter option fetch will be triggered only on cascaded filters selection and reset
    // on reset action filterSelected.filter_type is not sent, hence is undefined
    if (
      (isEmpty(dependency) ||
        filterSelected.filter_type === "cascaded" ||
        isUndefined(filterSelected.filter_type)) &&
      filterSelected.dimension !== "custom"
    ) {
      // sending only cascaded filters in filter list
      let cascadedSelectionDependency = selectionDependency.filter((key) => {
        return key.filter_type === "cascaded";
      });
      let mappedKeysList = initialFilterElements.filter(
        (item) => item.mappedKey
      );

      let mappingKeysObject = {};
      mappedKeysList.forEach((item) => {
        mappingKeysObject[item.column_name] = item.mappedKey;
      });

      cascadedSelectionDependency = cascadedSelectionDependency.map((dep) => {
        if (Object.keys(mappingKeysObject).includes(dep.attribute_name)) {
          return {
            ...dep,
            attribute_name: mappingKeysObject[dep.attribute_name],
            filter_id: mappingKeysObject[dep.attribute_name],
          };
        } else return dep;
      });

      cascadedSelectionDependency = isCrossDimensionFilter
        ? cascadedSelectionDependency
        : cascadedSelectionDependency.filter(
            (filter) => filter.dimension === filterDimension
          );

      // removing custom dimension and non cascaded filters before sending in attributes list
      const filterDashboardData = await getCombinedFilterDashboardData(
        initialFilterElements.filter(
          (filter) =>
            filter.dimension != "custom" && filter.type != "non-cascaded"
        ),
        cascadedSelectionDependency,
        screen_name
      );

      if (Object.keys(filterDashboardData).includes("mapping_key")) {
        mappedKeys = filterDashboardData.mapping_key;
      }

      initialFilterElements = initialFilterElements.map((key) => {
        if (key.type === "cascaded") {
          const options = filterDashboardData[key.column_name]
            ? filterDashboardData[key.column_name]
            : [];
          key.initialData = options.map((item) => {
            return mapDataToLabel(item);
          });
          key.mappedKey = getMappedKeyForFilter(
            filterDashboardData,
            key.column_name
          );
        }
        return key;
      });

      selectionDependency = isCrossDimensionFilter
        ? getAllDimensionDependency(
            filterDimension,
            selectionDependency,
            filterDependency
          )
        : selectionDependency;
    }
    return {
      initialFilterElements,
      selectionDependency,
      mappedKeys,
    };
  } catch (error) {
    return {
      initialFilterElements: [],
      selectionDependency,
    };
  }
};

/**
 * Returns combined filter dependency using current dependency list
 * and new dependency set from filter group
 * @param {String} dimension - dimension of new dependency
 * @param {Array} newDependencyList - new dependency
 * @param {Array} currentDependencyList - current dependency list
 * @returns Array - combined filter dependency
 */

export const getAllDimensionDependency = (
  dimension,
  newDependencyList,
  currentDependencyList = []
) => {
  // create a set object of filter ids in new dependency
  const newFilterDependencySet = new Set(
    newDependencyList.map((item) => item.filter_id)
  );

  const updatedCurrentDependencyList = currentDependencyList.filter(
    (item) =>
      /**
       * Remove filter data from current depedency
       * if, filter data is present in new dependency set
       * or filter data is of same dimension as new dependency dimension
       */

      !(
        newFilterDependencySet.has(item.attribute_name) ||
        item.dimension === dimension
      )
  );

  // combine updated dependency and new depedency
  return [...newDependencyList, ...updatedCurrentDependencyList];
};

/**
 * @func
 * @desc Return structure object to utilize as filter options
 * @param {Array} data Array of Objects
 * @returns {Array}
 */
export const generateFilterValues = (data) => {
  const filterValues = data.map((item) => {
    return {
      label: item.name,
      value: item.name,
    };
  });
  return filterValues;
};

// formats filter configurations sent from page component
export const formattedFilterConfiguration = (
  filterConfigId,
  filterConfigData,
  screenName,
  dependencyData
) => {
  const filterConfig = filterConfigData.map((item, index) => {
    // dynamically setting filter dimension from filter data if expectedFilterDimensions is not passed
    if (!item.expectedFilterDimensions) {
      item.expectedFilterDimensions = uniq(
        item.filterDashboardData.map((filter) => filter.dimension)
      );
    }

    // Dynamically setting defaultExpanded for Filter Accordion if expectedFilterAccordionExpansions is not passed
    if (!item.expectedFilterAccordionExpansions) {
      let defaultExpansions = [];
      item.filterDashboardData.map((filter) => {
        defaultExpansions[filter.dimension] = filter.defaultExpanded;
      });
      item.expectedFilterAccordionExpansions = defaultExpansions;
    }

    const updatedItem = {
      ...item,
      originalFilterDashboardData: item.filterDashboardData,
      filterDashboardData: item.filterDashboardData,
      filterDependencyData: [],
      filterDashboardClassification: item.expectedFilterDimensions.map(
        (dimension) => {
          return {
            dimension,
            screenName: `${kebabCase(
              screenName
            )}${"-"}${dimension}${"-"}${index}`,
            filterLabel: captializeStringIfCamelCase(
              dynamicLabelsBasedOnTenant(dimension, "core")
            ),
            customFilterComponent:
              item.customFilterComponent?.dimension === dimension
                ? item.customFilterComponent?.component
                : null,
            onReset: item.onReset,
            defaultExpanded:
            item.expectedFilterAccordionExpansions[dimension] === undefined ||
            item.expectedFilterAccordionExpansions[dimension] === true  
                ? true
                : false,
          };
        }
      ),
    };
    return updatedItem;
  });

  let obj = {};
  obj[filterConfigId] = {
    filterConfig,
    appliedFilterData: {
      filterHeader: "",
      dependencyData: dependencyData?.length > 0 ? dependencyData : [],
    },
  };

  return obj;
};

export const formatSelectedFiltersData = (
  filterConfigData,
  screenName,
  dependencyData
) => {
  let formattedFilterData = {};
  filterConfigData?.forEach((item, index) => {
    dependencyData?.forEach((data) => {
      const label = `${kebabCase(screenName)}${"-"}${
        data.dimension
      }${"-"}${index}`;
      if (!formattedFilterData[label]) {
        formattedFilterData[label] = [];
      }
      const formattedData = {
        filter_id: data.filter_id,
        filter_type: data.filter_type,
        dimension: data.dimension,
        values: data?.values.map((value) => {
          return {
            id: value,
            label: value,
            value: value,
          };
        }),
      };
      formattedFilterData[label].push(formattedData);
    });
  });
  return formattedFilterData;
};

const displaySnackMessages = (message, variance) => {
  store.dispatch(
    addSnack({
      message: message,
      options: {
        variant: variance,
      },
    })
  );
};

/**
 *
 * @param {string or Array} screenName
 * @returns string
 * If the uamScreenName is an array as some of the routes were defined as array in layout
 * we return first index as first index corresponds to screenName
 * If it is of string type, then we simply return the screenName back
 */
export const getUAMScreenName = (screenName) => {
  if (Array.isArray(screenName)) {
    return screenName[0];
  }
  return screenName;
};
