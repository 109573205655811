import {
  SET_PROJECTS,
  SET_DATASET,
  SET_TABLES,
  SET_TABLE_DESC,
  UPDATE_TABLE_DESC_SUCCESS,
  SET_TABLE_COLUMN_DESC,
  SET_SCHEMA_DATA,
  SET_QUERY_DATA,
  SET_PROMPT_DATA,
  UPDATE_TABLE_COLUMN_DESCRIPTION_SUCCESS,
  SET_PYTHON_PROMPT,
  SET_EXCEL_DATA,
  RESET_EXCEL_DATA,
  SET_LAST_PROMPT,
} from "../actions/types";

export const initialState = {
  projectOptions: [],
  dataSetOptions: [],
  tableOptions: [],
  tableDesc: [],
  tableColumnDescriptions: {},
  schemaData: {},
  promptData: {},
  queryData: {},
  pythonPrompt: '',
  excelData: [],
  lastPromptData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        projectOptions: action.payload,
        dataSetOptions: [],
      };
    case SET_DATASET:
      return {
        ...state,
        dataSetOptions: action.payload,
        tableOptions: [],
      };
    case SET_TABLES:
      return {
        ...state,
        tableOptions: action.payload,
      };
    case SET_TABLE_DESC:
      return {
        ...state,
        tableDesc: action.payload,
      };
    case UPDATE_TABLE_DESC_SUCCESS:
      return {
        ...state,
        tableDesc: { ...state.tableDesc, ...action.payload },
      };
    case SET_TABLE_COLUMN_DESC:
      return {
        ...state,
        tableColumnDescriptions: action.payload,
      };
    case SET_SCHEMA_DATA:
      return {
        ...state,
        schemaData: action.payload,
      };
    case UPDATE_TABLE_COLUMN_DESCRIPTION_SUCCESS:
      return {
        ...state,
      };
    case SET_PROMPT_DATA:
      return {
        ...state,
        promptData: action.payload,
      };
    case SET_QUERY_DATA:
      return {
        ...state,
        queryData: action.payload,
      };
    case SET_PYTHON_PROMPT:
      return {
        ...state,
        pythonPrompt: action.payload,
      };
    case SET_EXCEL_DATA:
      return {
        ...state,
        excelData: action.payload,
      };
    case RESET_EXCEL_DATA:
      return {
        ...state,
        excelData: [],
      };
    case SET_LAST_PROMPT:
      return {
        ...state,
        lastPromptData: action.payload,
      };
    default:
      return state;
  }
}
